import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  EntityMenuMobile,
  MenuControlled,
} from "../../../common/components";
import { MenuOption } from "../../../common/components/Menu/Menu";
import { useSelector } from "../../../common/hooks/useSelector";
import DesignService from "../../../service/DesignService";
import DesignStateService from "../../../service/DesignStateService";
import { useCart } from "../../../utils/CartHooks";
import { ROUTES } from "../../../utils/Constant";
import { useCurrency } from "../../../utils/Currencies";
import { useDesignFetcher } from "../../../utils/DesignsFetcherHooks";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import DeleteModal from "../../DeleteModal/DeleteModal";
import MoveProjectModal from "../../MoveProjectModal/MoveProjectModal";
import RenameModal from "../../RenameModal/RenameModal";
import CreateMockupModal from "./components/CreateMockupModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  target: Element | null;
  designId: string;
  projectId: string;
  designName: string;
  updatedAt: Date;
  onUpdate?: () => Promise<void>;
  setIsLoading?: (isLoading: boolean) => void;
}

const DesignCardMenu = ({
  isOpen,
  onClose,
  target,
  designId,
  projectId,
  designName,
  updatedAt,
  onUpdate,
  setIsLoading,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useThemeBreakpoints();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMoveProjectModal, setShowMoveProjectModal] = useState(false);
  const [showCreateMockupModal, setShowCreateMockupModal] = useState(false);
  const {
    canDeleteDesign = true,
    canRenameDesign = true,
    canMoveDesign = true,
    canAddDesign = true,
  } = useSelector((state) => state.userInfo);

  const preventRedirectionOnEvent = location.pathname.includes(ROUTES.HOME);

  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();
  const { addToCart, fetchCartItemsAndUpdateStore } = useCart();
  const { getCurrency } = useCurrency();

  const onRenameCloseClick = () => {
    setShowRenameModal(false);
  };

  const update = async () => {
    await (onUpdate
      ? onUpdate()
      : fetchMyDesignsAndUpdateStore(projectId, true));
  };

  const onDeleteClickClose = () => {
    setShowDeleteModal(false);
  };
  const onMoveClickClose = () => {
    setShowMoveProjectModal(false);
  };
  const onRename = useCallback(
    async (name: string) => {
      try {
        setIsLoading?.(true);
        await DesignService.renameDesign({ name, designId: designId });
        update();
      } catch (e) {
        console.error(e);
      } finally {
        setShowRenameModal(false);
        setIsLoading?.(false);
      }
    },
    [designId, fetchMyDesignsAndUpdateStore, projectId]
  );

  const onDelete = useCallback(async () => {
    try {
      setIsLoading?.(true);
      await DesignService.deleteDesign({ id: designId });
      await update();
      fetchCartItemsAndUpdateStore(getCurrency()); //Because deleting a design can impact the cart
    } catch (e) {
      console.error(e);
    } finally {
      setShowDeleteModal(false);
      setIsLoading?.(false);
      !preventRedirectionOnEvent &&
        navigate(generatePath(ROUTES.DESIGNS, { projectId }));
    }
  }, [
    projectId,
    designId,
    fetchMyDesignsAndUpdateStore,
    fetchCartItemsAndUpdateStore,
    navigate,
    getCurrency,
  ]);

  const { fetchProductDefinition } = useDesignFetcher();

  const onAddToCart = async () => {
    //First get the Design State info of the design, then add to cart the design
    const {
      data: { design },
    } = await DesignStateService.getDesignState(designId);

    const {
      productDefinition: { sizes },
    } = await fetchProductDefinition(design.family, design.product);

    addToCart(designId, sizes, getCurrency(), design.product.name);
  };

  const onDuplicate = async () => {
    onClose();
    setIsLoading?.(true);
    const res = await DesignStateService.duplicateDesignState({
      designId: designId,
    });

    const duplicatedDesignState = res.data;
    try {
      await DesignService.addDesign(
        {
          projectId,
          designId: duplicatedDesignState.designId,
          name: `${designName + " 1"}`,
        },
        duplicatedDesignState.design.product
      );

      await update();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading?.(false);
      !preventRedirectionOnEvent &&
        navigate(generatePath(ROUTES.DESIGNS, { projectId }));
    }
  };

  const handleMenuItemClick = (callback: any) => () => {
    callback();
    onClose();
  };

  const options: MenuOption[] = [
    {
      labelKey: "general.rename",
      disabled: !canRenameDesign,
      onClick: handleMenuItemClick(() => setShowRenameModal(true)),
      hideIf: isMobile,
    },
    {
      labelKey: "design.delete_menu",
      disabled: !canDeleteDesign,
      onClick: handleMenuItemClick(() => setShowDeleteModal(true)),
    },
    {
      labelKey: "design.add_to_cart",
      onClick: handleMenuItemClick(onAddToCart),
    },
    {
      labelKey: "design.duplicate",
      disabled: !canAddDesign,
      onClick: handleMenuItemClick(onDuplicate),
    },
    {
      labelKey: "design.move_project",
      disabled: !canMoveDesign,
      onClick: handleMenuItemClick(() => setShowMoveProjectModal(true)),
    },
    {
      labelKey: "design.createMockup.title",
      adminOnly: true,
      onClick: handleMenuItemClick(() => setShowCreateMockupModal(true)),
    },
  ];

  return (
    <Box>
      {isMobile ? (
        <EntityMenuMobile
          isOpen={isOpen}
          onClose={onClose}
          options={options}
          name={designName || t("design.untitled_design")}
          onSave={onRename}
          updatedAt={updatedAt}
        />
      ) : (
        <MenuControlled
          options={options}
          isOpen={isOpen}
          onClose={onClose}
          target={target}
          data-test={`menu.${designName}`}
        />
      )}
      <RenameModal
        open={showRenameModal}
        onRename={onRename}
        oldName={designName || t("design.untitled_design")}
        onClose={onRenameCloseClick}
        title={t("design.rename_title")}
      />
      <DeleteModal
        open={showDeleteModal}
        onDelete={onDelete}
        title={t("design.delete_modal_title")}
        message={t("design.delete_modal_message")}
        onClose={onDeleteClickClose}
      />
      <MoveProjectModal
        open={showMoveProjectModal}
        onClose={onMoveClickClose}
        designId={designId}
        projectId={projectId}
      />
      <CreateMockupModal
        open={showCreateMockupModal}
        onClose={() => setShowCreateMockupModal(false)}
        designId={designId}
      />
    </Box>
  );
};

export default memo(DesignCardMenu);

import Beanie from "../assets/product_images/Menu_Beanie.svg";
import CasualSocks from "../assets/product_images/Menu_Casual_Socks.svg";
import Hoodie from "../assets/product_images/Menu_Hoodie.svg";
import HoodieSnap from "../assets/product_images/Menu_Hoodie_Snap.svg";
import HoodieJunior from "../assets/product_images/Menu_Hoodie_junior.svg";
import HoodieWoman from "../assets/product_images/Menu_Hoodie_women.svg";
import HoodieZip from "../assets/product_images/Menu_Hoodie_zip.svg";
import LongSleeve from "../assets/product_images/Menu_Longsleeve.svg";
import Mockneck from "../assets/product_images/Menu_Mockneck.svg";
import MockneckZip from "../assets/product_images/Menu_Mockneck_zip.svg";
import PolarVest from "../assets/product_images/Menu_Polar_Vest.svg";
import PoloM from "../assets/product_images/Menu_Polo_M.svg";
import PoloW from "../assets/product_images/Menu_Polo_W.svg";
import BeaniePompom from "../assets/product_images/Menu_Pompom_Beanie.svg";
import Pullover from "../assets/product_images/Menu_Pullover.svg";
import PulloverZip from "../assets/product_images/Menu_Pullover_Zip.svg";
import PulloverJunior from "../assets/product_images/Menu_Pullover_junior.svg";
import SleevelessVest from "../assets/product_images/Menu_Sleeveless.svg";
import SportSocks from "../assets/product_images/Menu_Sport_Socks.svg";
import SweatPants from "../assets/product_images/Menu_Sweatpants.svg";
import SweatShirt from "../assets/product_images/Menu_Sweatshirt.svg";
import TShirt from "../assets/product_images/Menu_T-shirt.svg";
import TShirtJunior from "../assets/product_images/Menu_T-shirt_junior.svg";
import WomanTShirt from "../assets/product_images/Menu_T-shirt_women.svg";
import BeanieWaffle from "../assets/product_images/Menu_Waffle_Beanie.svg";
import Sleevelees_Jacket_M from "../assets/product_images/Sleevelees_Jacket_M.svg";
import Sleevelees_Jacket_W from "../assets/product_images/Sleevelees_Jacket_W.svg";
import { Product } from "../types";

export const ROUTES = {
  TEAM_PROJECTS: "/teams/:teamId",
  DESIGNS: "/projects/:projectId",
  DESIGNS_SHOW_NEW: "/projects/:projectId/newdesign",
  LOGIN_WITH_TOKEN: "/login/:token",
  LOGIN: "/login",
  SIGN_UP: "/signup",
  SIGN_UP_WITH_TOKEN: "/signup/:token",
  RESET_PASSWORD: "/resetpassword",
  UPDATE_PASSWORD: "/resetpassword/:token",
  HOME: "/home",
  REFER_A_FRIEND: "/referral",
  CART: "/cart",
  ORDERS: "/orders",
  ADMIN_PURCHASED_ORDERS: "/admin/orders",
  ADMIN_TECHPACK_ORDER: "/admin/techpack/order/:orderId",
  ADMIN_TECHPACK_PREORDER: "/admin/techpack/preorder/:preorderId",
  ADMIN_PREORDER: "/admin/preorder/:preorderId",
  ADMIN_VALIDATE_REFERRAL_CODE: "/admin/validate/referralcode",
  ORDERS_SESSION: "/orders/session/:sessionId", //From checkout success of Stripe
  ORDERS_PREORDERS: "/orders/request/:preorderId", //From preorder
  SIZES: "/projects/:projectId/collectsizes",
  COLLECT_SIZE: "/sizes/:token",
  COLLECT_SIZE_SUCCESS: "/sizes/success/:token",
  STUDIO: "/projects/:projectId/designs/:designId",
  STUDIO_PURCHASED_DESIGN:
    "/orders/:orderType/:orderId/designs/:designId/from/:historyFrom",
  JOIN: "/join/:token",
  SHIPPING_ADDRESS: "/cart/address",
  ERROR: "/error",
};

export const EXTERNAL_ROUTE = {
  SUPPORT: "https://www.jameo.com/faq",
};

/**
 * Artworks types (aligned with Specs artwork types)
 */
export const ARTWORK_TYPES = Object.freeze({
  TECHPACK_PRODUCTION: "techpackproduction",
});

/**
 * Asset behaves like (aligned with Specs behavesLike)
 */
export enum BEHAVES_LIKE {
  DETAILED_PRINT_TYPE = "detailedPrintType",
  EMBROIDERY_PATCH_TYPE = "embroideryPatchType",
  LEATHER_TAG_TYPE = "leatherTagType",
  SCREENPRINT_TYPE = "screenprintType",
  WOVEN_TAG_TYPE = "wovenTagType",
}

export enum ONBOARDING_STEPS {
  EMAIL = "email",
  LOGIN = "login",
  SIGNUP = "signup",
  ABOUT_PROJECT = "about",
}

export enum ABOUT_PROJECT_STEPS {
  PROJEC_TYPE = "projectType",
  TEAM_NAME = "teamName",
  PROJECT_SIZE = "projectSize",
}

export enum PROJECT_SIZES {
  LESS_20 = "0-19",
  LESS_50 = "20-49",
  LESS_150 = "50-149",
  LESS_500 = "150-499",
  LESS_5000 = "500-4999",
  MORE_5000 = "5000-",
}

//About Project, for who
export enum PROJECT_TYPES {
  COMPANY = "company",
  STUDENT = "student",
  PERSONAL = "personal",
  OTHER = "other",
}

//NOTE - Temporary, so that in prod we only see the products that are "approved"
const comingSoon =
  process.env.REACT_APP_ENV !== "dev" &&
  process.env.REACT_APP_ENV !== "staging";

export const ProductList: Product[] = [
    ...(comingSoon ? [] :[
        {
          family: { name: "shirt", version: "1.0" },
          product: { name: "tshirtEco", version: "1.0" },
          labelTag: "products.tshirtEco.name",
          imgName: TShirt,
          minQty: 20,
          comingSoon
        },
        {
          family: { name: "hoodie", version: "1.0" },
          product: { name: "hoodieEco", version: "1.0" },
          labelTag: "products.hoodieEco.name",
          imgName: Hoodie,
          minQty: 20,
          comingSoon
        },
    ]),
  {
    family: { name: "shirt", version: "1.0" },
    product: { name: "tshirt", version: "1.0" },
    labelTag: "products.tshirt.name",
    imgName: TShirt,
    minQty: 20,
  },
  {
    family: { name: "shirt", version: "1.0" },
    product: { name: "womanTshirt", version: "1.0" },
    labelTag: "products.womanTshirt.name",
    imgName: WomanTShirt,
    minQty: 20,
  },
  {
    family: { name: "shirt", version: "1.0" },
    product: { name: "tshirtJunior", version: "1.0" },
    labelTag: "products.tshirtJunior.name",
    imgName: TShirtJunior,
    minQty: 20,
  },
  {
    family: { name: "shirt", version: "1.0" },
    product: { name: "longsleeve", version: "1.0" },
    labelTag: "products.longsleeve.name",
    imgName: LongSleeve,
    minQty: 20,
  },
  {
    family: { name: "polo", version: "1.0" },
    product: { name: "poloMen", version: "1.0" },
    labelTag: "products.poloMen.name",
    imgName: PoloM,
    minQty: 50,
  },
  {
    family: { name: "polo", version: "1.0" },
    product: { name: "poloWomen", version: "1.0" },
    labelTag: "products.poloWomen.name",
    imgName: PoloW,
    minQty: 50,
  },
  {
    family: { name: "sweatshirt", version: "1.0" },
    product: { name: "sweatshirt", version: "1.0" },
    labelTag: "products.sweatshirt.name",
    imgName: SweatShirt,
    minQty: 20,
  },
  {
    family: { name: "hoodie", version: "1.0" },
    product: { name: "hoodie", version: "1.0" },
    labelTag: "products.hoodie.name",
    imgName: Hoodie,
    minQty: 20,
  },
  {
    family: { name: "hoodie", version: "1.0" },
    product: { name: "hoodieZip", version: "1.0" },
    labelTag: "products.hoodieZip.name",
    imgName: HoodieZip,
    minQty: 20,
  },
  {
    family: { name: "hoodie", version: "1.0" },
    product: { name: "hoodieWoman", version: "1.0" },
    labelTag: "products.hoodieWoman.name",
    imgName: HoodieWoman,
    minQty: 20,
  },
  {
    family: { name: "hoodie", version: "1.0" },
    product: { name: "hoodieSnap", version: "1.0" },
    labelTag: "products.hoodieSnap.name",
    imgName: HoodieSnap,
    minQty: 20,
  },
  {
    family: { name: "hoodie", version: "1.0" },
    product: { name: "hoodieJunior", version: "1.0" },
    labelTag: "products.hoodieJunior.name",
    imgName: HoodieJunior,
    minQty: 20,
    comingSoon: comingSoon,
  },
  {
    family: { name: "pullover", version: "1.0" },
    product: { name: "pulloverSnap", version: "1.0" },
    labelTag: "products.pulloverSnap.name",
    imgName: Pullover,
    minQty: 20,
  },
  {
    family: { name: "pullover", version: "1.0" },
    product: { name: "pulloverJunior", version: "1.0" },
    labelTag: "products.pulloverJunior.name",
    imgName: PulloverJunior,
    minQty: 20,
  },
  {
    family: { name: "pullover", version: "1.0" },
    product: { name: "pulloverZip", version: "1.0" },
    labelTag: "products.pulloverZip.name",
    imgName: PulloverZip,
    minQty: 20,
  },
  {
    family: { name: "pullover", version: "1.0" },
    product: { name: "pulloverFullSnap", version: "1.0" },
    labelTag: "products.pulloverFullSnap.name",
    imgName: PolarVest,
    minQty: 20,
  },
  {
    family: { name: "sleevelessPolar", version: "1.0" },
    product: { name: "sleevelessPolar", version: "1.0" },
    labelTag: "products.sleevelessPolar.name",
    imgName: SleevelessVest,
    minQty: 20,
  },
  {
    family: { name: "mockneck", version: "1.0" },
    product: { name: "mockneck", version: "1.0" },
    labelTag: "products.mockneck.name",
    imgName: Mockneck,
    minQty: 20,
  },
  {
    family: { name: "mockneck", version: "1.0" },
    product: { name: "mockneckFullZip", version: "1.0" },
    labelTag: "products.mockneckFullZip.name",
    imgName: MockneckZip,
    minQty: 20,
  },
  {
    family: { name: "sleevelessJacket", version: "1.0" },
    product: { name: "sleevelessJacketMen", version: "1.0" },
    labelTag: "products.sleevelessJacketMen.name",
    imgName: Sleevelees_Jacket_M,
    minQty: 20,
  },
  {
    family: { name: "sleevelessJacket", version: "1.0" },
    product: { name: "sleevelessJacketWoman", version: "1.0" },
    labelTag: "products.sleevelessJacketWoman.name",
    imgName: Sleevelees_Jacket_W,
    minQty: 20,
  },
  {
    family: { name: "sweatpants", version: "1.0" },
    product: { name: "sweatpants", version: "1.0" },
    labelTag: "products.sweatpants.name",
    imgName: SweatPants,
    minQty: 20,
  },
  {
    family: { name: "beanie", version: "1.0" },
    product: { name: "beanie", version: "1.0" },
    labelTag: "products.beanie.name",
    imgName: Beanie,
    minQty: 20,
  },
  {
    family: { name: "beanie", version: "1.0" },
    product: { name: "waffleBeanie", version: "1.0" },
    labelTag: "products.waffleBeanie.name",
    imgName: BeanieWaffle,
    minQty: 20,
  },
  {
    family: { name: "beanie", version: "1.0" },
    product: { name: "pompomBeanie", version: "1.0" },
    labelTag: "products.pompomBeanie.name",
    imgName: BeaniePompom,
    minQty: 20,
  },
  {
    family: { name: "socks", version: "1.0" },
    product: { name: "casualSocks", version: "1.0" },
    labelTag: "products.casualSocks.name",
    imgName: CasualSocks,
    minQty: 60,
  },
  {
    family: { name: "socks", version: "1.0" },
    product: { name: "sportSocks", version: "1.0" },
    labelTag: "products.sportSocks.name",
    imgName: SportSocks,
    minQty: 60,
  },
  // {
  //   family: { name: "mask", version: "1.0" },
  //   product: { name: "mask", version: "1.0" },
  //   labelTag: "products.mask.name",
  //   imgName: Mask,
  //   minQty: 50,
  //   comingSoon: comingSoon, //Remove when production has approved this product
  // },
];

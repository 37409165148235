import { Divider, Typography } from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../common/components";
import { Box } from "../../../common/components/Box";
import Checkbox from "../../../common/components/Checkbox/Checkbox";
import { useSelector } from "../../../common/hooks/useSelector";
import config from "../../../config";
import { FormContext } from "../../../context";
import PreOrderService from "../../../service/PreOrderService";
import { palette, spacing, Styles } from "../../../styles/theme";
import { useCart } from "../../../utils/CartHooks";
import { canContinueToPayment } from "../../../utils/CartUtils";
import { ROUTES } from "../../../utils/Constant";
import { useCurrency } from "../../../utils/Currencies";
import { getLanguage } from "../../../utils/LocaleUtil";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import { ShippingAddressContainer } from "../../ShippingAddressContainer";
import { CheckoutForm } from "../Cart";
import CartItemPreview from "../CartItemPreview/CartItemPreview";
import NextStepInfo from "./components/NextStepInfo";

const useStyles = (fullWidth: boolean): Styles => ({
  container: {
    maxWidth: fullWidth ? "100%" : 400,
    display: "flex",
    flexDirection: "column",
    gap: { xs: spacing.groupingMobile, lg: spacing.groupingDesktop },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  total: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: spacing.mediumMobile, lg: spacing.mediumDesktop },
    mb: { xs: "8px", lg: 0 },
  },
  items: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: { xs: spacing.mediumMobile, lg: spacing.mediumDesktop },
  },
  updateCurrencyLink: {
    color: palette.blue.secondary,
    cursor: "pointer",
    ":hover": {
      textDecoration: "underline",
    },
  },
});

const CartPreview = () => {
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();
  const location = useLocation();
  const isShippingStep = location.pathname === ROUTES.SHIPPING_ADDRESS;
  const isMobileShippingStep = isMobile && isShippingStep;
  const styles = useStyles(isMobileShippingStep);
  const navigate = useNavigate();
  const userInfos = useSelector((state) => state.userInfo);
  const { formik } = useContext(FormContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { pricingCartData, loaded, isSaving } = useSelector(
    (state) => state.cart
  );
  const { fetchCartItemsAndUpdateStore } = useCart();
  // const [promoCode, setPromoCode] = useState<string>("");

  const {
    billingAddress,
    shippingAddress,
    finalDesignValidated,
    termsAndConditionsAccepted,
  } = formik?.values as CheckoutForm;

  const { NarrowCurrencyFormatter, getCurrency, isUSD, setUSD, setCAD } =
    useCurrency();

  const setUSDCurrency = () => {
    setUSD();
  };

  const setCADCurrency = () => {
    setCAD();
  };

  const items = pricingCartData.items;

  const areAllQuantitiesAvailable = useMemo(
    () => pricingCartData.hasMinimum && !pricingCartData.hasReachedMaximum,
    [pricingCartData.hasMinimum, pricingCartData.hasReachedMaximum]
  );

  //Can't use memo because shippingAddress object doesn't change, it's its fields that are changing
  const isFormValid = () => {
    return formik?.isValid;
  };

  const onContinueToShippingClick = useCallback(async () => {
    navigate(ROUTES.SHIPPING_ADDRESS);
  }, []);

  const onPreOrder = useCallback(async () => {
    setSubmitLoading(true);
    const itemsToPreOrder = items.map((item) => {
      return { designId: item.designId, sizes: item.sizes };
    });

    const preOrderData = {
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
      items: itemsToPreOrder,
      locale: getLanguage(),
      currency: getCurrency(),
      promoCode: "",
    };

    const checkoutRes = await PreOrderService.addPreOrder(preOrderData);

    //TODO: Error message to user when failing
    if (checkoutRes.status !== 200) {
      console.error("Something somewhere went wrong!", checkoutRes);
      return;
    }

    const preorderId = checkoutRes.data.data.id;

    fetchCartItemsAndUpdateStore();
    navigate(
      generatePath(ROUTES.ORDERS_PREORDERS, {
        preorderId: preorderId,
      })
    );
    setSubmitLoading(false);
  }, [navigate, shippingAddress, billingAddress, items]);

  const containsDeprecatedDesign = useMemo(() => {
    return (
      items.map((i) => i?.deprecatedDesign).some((e) => e) &&
      config.blockPaymentWhenDesignIsDeprecated
    );
  }, [items]);

  return (
    <Box key="preview" sx={styles.container}>
      {isMobileShippingStep && (
        <Typography variant="h1" mt={spacing.wrapperMobile}>
          {t(
            areAllQuantitiesAvailable
              ? "checkout.checkout"
              : "checkout.requestQuote"
          )}
        </Typography>
      )}
      <Box sx={styles.items}>
        {items.map((item) => (
          <CartItemPreview {...item} key={item.id} />
        ))}
      </Box>

      <Divider />

      <Box sx={styles.total}>
        <Box sx={styles.row}>
          <Typography variant="textRegular">
            {t("checkout.shipping")}
          </Typography>
          <Typography variant="textRegular">
            {!containsDeprecatedDesign &&
            pricingCartData.hasMinimum &&
            pricingCartData.hasMinimumPerSize &&
            !pricingCartData.hasReachedMaximum
              ? NarrowCurrencyFormatter?.format(
                  pricingCartData.priceOfShipping ?? 0
                )
              : t("checkout.tbd")}
          </Typography>
        </Box>
        <Box sx={styles.row}>
          <Typography variant="textRegular">{t("checkout.taxes")}</Typography>
          <Typography variant="textRegular">{t("checkout.tbd")}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={{ xs: spacing.xsMobile, lg: spacing.xsDesktop }}
        >
          <Box sx={styles.row}>
            <Typography variant="textRegularBold">
              {t("checkout.subtotal", { currency: getCurrency() })}
            </Typography>
            <Typography variant="textRegularBold">
              {!containsDeprecatedDesign &&
              pricingCartData.hasMinimum &&
              pricingCartData.hasMinimumPerSize &&
              !pricingCartData.hasReachedMaximum
                ? NarrowCurrencyFormatter?.format(pricingCartData.price ?? 0)
                : t("checkout.tbd")}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="textSm"
              onClick={(e) => {
                e.preventDefault();
                (isUSD() ? setCADCurrency : setUSDCurrency)();
              }}
              sx={styles.updateCurrencyLink}
            >
              {isUSD()
                ? t("pricing.change_currency_cad")
                : t("pricing.change_currency_usd")}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* {isShippingStep && (
        <Box>
          <Input
            label={t("checkout.promoCode")}
            name="promoCode"
            value={promoCode}
            onChange={(e) => setPromoCode(e.currentTarget.value)}
            fullWidth
          />
        </Box>
      )} */}
      {isMobileShippingStep && (
        <Box
          sx={{
            background: palette.grey.lightBackground,
            px: `${spacing.wrapperMobile}`,
            mx: `-${spacing.wrapperMobile}`,
            py: `${spacing.regularMobile}`,
          }}
        >
          <ShippingAddressContainer />
        </Box>
      )}
      {isShippingStep && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
        >
          <NextStepInfo />
          <Checkbox
            onChange={async (_, value) => {
              await formik?.setFieldValue("finalDesignValidated", value, true);
              await formik?.setFieldValue(
                "termsAndConditionsAccepted",
                value,
                true
              );
            }}
            label={t("checkout.termsAndConditions.text")}
            linkUrl={t("checkout.termsAndConditions.linkUrl")}
            textUrl={t("checkout.termsAndConditions.textUrl")}
            value={finalDesignValidated && termsAndConditionsAccepted}
          />
        </Box>
      )}
      {!isShippingStep ? (
        <Button
          disabled={
            !canContinueToPayment(isSaving, items, pricingCartData, userInfos)
          }
          onClick={onContinueToShippingClick}
          fullWidth
        >
          {isSaving ? t("checkout.calculating") : t("general.continue")}
        </Button>
      ) : (
        <Button
          disabled={!isFormValid() || !loaded || submitLoading}
          onClick={onPreOrder}
          fullWidth
        >
          {areAllQuantitiesAvailable
            ? t("checkout.submitOrder")
            : t("checkout.requestQuoteBtn")}
        </Button>
      )}
    </Box>
  );
};

export default CartPreview;

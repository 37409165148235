import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { generatePath, useNavigate } from "react-router-dom";
import { NavLink } from "../../../common/components";
import { useSelector } from "../../../common/hooks/useSelector";
import { AppToolbarContext } from "../../../context/AppToolbar";
import ProjectService from "../../../service/ProjectService";
import TeamService from "../../../service/TeamService";
import { spacing } from "../../../styles/theme";
import { ROUTES } from "../../../utils/Constant";
import { useProjectFetcher } from "../../../utils/ProjectsFetcherHooks";
import { useTeamFetcher } from "../../../utils/TeamsFetcherHooks";

export const CreateTeam = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggleNavigationDrawerOpen } = useContext(AppToolbarContext);

  const teamsState = useSelector((state) => state.myTeams);
  const teams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);

  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();
  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();

  const onClick = useCallback(async () => {
    const bodyData = {
      name: `${t("team.team")} ${teams.length + 1}`,
    };

    const {
      data: {
        data: { id },
      },
    } = await TeamService.createTeam(bodyData);

    const projectBodyData = {
      name: `${t("project.project")}`,
      teamId: id,
    };

    await ProjectService.createProject(projectBodyData);

    await fetchMyTeamsAndUpdateStore();
    await fetchMyProjectsAndUpdateStore();
    navigate(generatePath(ROUTES.TEAM_PROJECTS, { teamId: id }));
    toggleNavigationDrawerOpen?.();
  }, [navigate, t, teams, fetchMyTeamsAndUpdateStore]);

  return (
    <NavLink
      to=""
      startIcon="person_add"
      content={t("navigation.create_team")}
      onClick={onClick}
      style={{ paddingRight: spacing.mediumDesktop }}
      data-test="leftNavigation.createTeam"
    />
  );
};

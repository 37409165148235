import { Buffer } from "buffer";
import { throttle } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Id, toast } from "react-toastify";
import ReconnectingWebSocket from "reconnecting-websocket";
import { InflateStream } from "zlibt2";
import { useMoment } from "../common/hooks/useMoment";
import { DESIGNS_ACTION_ENUM } from "../common/reducers/designs";
import DesignStateService from "../service/DesignStateService";
import ImageService from "../service/ImageService";
import TeamService from "../service/TeamService";
import {
  DesignFamily,
  DesignFromGenerator,
  DesignProduct,
  ProductDefinition,
  ProductDefinitionPart,
  Teammate,
} from "../types";
import { ROUTES } from "./Constant";
import { useDesignFetcher } from "./DesignsFetcherHooks";

interface Selection {
  selectedDesignId?: string;
  selectedPart: string;
  selectedElementName: string;
  selectedIndex: string;
}

const useAttributeStyles = (
  partsDefinition: ProductDefinitionPart[],
  selection: Selection,
  isLoading: boolean
) => {
  const { t } = useTranslation();

  const partDef = useMemo(() => {
    if (isLoading || !partsDefinition) {
      return undefined;
    }

    return partsDefinition.find((p) => p.name === selection.selectedPart);
  }, [partsDefinition, selection.selectedPart, isLoading]);

  const partElementDef = useMemo(() => {
    if (!partDef || !partDef.elements) {
      return null;
    }
    return partDef.elements.find(
      (e) => e.name === selection.selectedElementName
    );
  }, [partDef, selection.selectedElementName]);

  const partElementStylesDef = useMemo(() => {
    if (!partElementDef || !partElementDef.styles) {
      return null;
    }
    return partElementDef.styles.map((s) => ({
      name: s.name,
      label: t(`specs.${s.name}`),
      display: s.display,
    }));
  }, [partElementDef, t]);

  return partElementStylesDef;
};

export enum DESIGN_CHANGE_ENUM {
  REFRESH = "refresh",
  ADD_DECORATION = "addDecoration",
  ON_REMOVE = "removeDecoration",
  ON_REINITIALIZE = "reinitializeDecoration",
  SET_COLOR = "setColor",
  SET_COLOR_COMBO = "setColorCombo",
  UPDATE_PRINT_COLOR = "updatePrintColor",
  SET_STYLE = "setStyle",
  SET_MODEL = "setModel",
  SET_DECORATION_LOCATION = "setDecorationLocation",
  SET_SIZE = "setDecorationSize",
  SET_DECORATION_TAG_TEXTURE = "setDecorationTagTextureName",
  SET_DECORATION_TAG = "setDecorationTagName",
  SET_DECORATION_TAG_BACKGROUND_COLOR = "setDecorationTagBackgroundColorName",
  SET_DECORATION_EMBROIDERY_PATCH_BACKGROUND_COLOR = "setDecorationPatchBackgroundColorName",
  SET_DECORATION_EMBROIDERY_PATCH_STITCH_COLOR = "setDecorationPatchStitchColorName",
  SET_REPEATING_PATTERN_DETAILS = "setDecorationRepeatingPattern",
  SET_DECORATION_ACTIVE = "setDecorationActive",
  SET_DECORATION_ASSET = "setDecorationAsset",
  CHANGE_DECORATION_TYPE = "changeDecorationType",
}

export const DEPRECATION_TYPE_ENUM = {
  DEPRECATED_STYLE: "deprecatedStyle",
  DEPRECATED_COLOR: "deprecatedColor",
};

interface DeprecatedDesignItem {
  type: string;
  partName: string;
  elementName: string;
  styleName: string;
  layerName?: string;
}

interface ColorBleedingItem {
  bleeding?: boolean;
  bledOn?: boolean;
  partName: string;
  elementName: string;
  styleName: string;
  layerName: string;
}

export const useDesignState = (socketRef: any, designId?: string) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [designWarning, setDesignWarning] = useState(false);
  const [migrationNeeded, setMigrationNeeded] = useState(false);
  const [colorWarning, setColorWarning] = useState(false);
  const [deprecatedDesignItems, setDeprecatedDesignItems] = useState<
    DeprecatedDesignItem[]
  >([]);
  const [colorBleedingItems, setColorBleedingItems] = useState<
    ColorBleedingItem[]
  >([]);
  const [loadingFailedErrorKey, setLoadingFailedErrorKey] = useState<
    Id | undefined
  >();
  const [hasLoadingFailed, setLoadingFailed] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [designState, setDesignState] = useState<DesignFromGenerator | null>(
    null
  );
  const [productDefinition, setProductDefinition] =
    useState<ProductDefinition | null>(null);
  const [tagOptions, setTagOptions] = useState(null);
  const [repeatingPatternOptions, setRepeatingPatternOptions] = useState(null);

  const [selection, setSelection] = useState<Selection>({
    selectedDesignId: "",
    selectedPart: "",
    selectedElementName: "",
    selectedIndex: "",
  });

  const { dispatch } = useStore();

  //Every time we select another design (so designId changes), then we need to reset the selection to the first part's main element
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!designState || !designState.design) {
      return;
    }

    //In flux, the design state is the old design state and the new state is not yet rendered
    if (designState.designId !== designId) {
      return;
    }

    //If the selected design is the same as this design, then we continue because we only select the first element once
    if (selection.selectedDesignId === designId) {
      return;
    }

    let firstPartName = "";
    let firstPartMainElementName = "";
    if (designState.design.parts.length > 0) {
      firstPartName = designState.design.parts[0].name;
      firstPartMainElementName = designState.design.parts[0].mainElement;
    }

    setSelection({
      selectedDesignId: designId,
      selectedPart: firstPartName,
      selectedElementName: firstPartMainElementName,
      selectedIndex: "",
    });
  }, [designState, designId, selection.selectedDesignId, isLoading]);

  const onSelectionChange = (
    partName: string,
    attributeName: string,
    index?: string
  ) => {
    setSelection({
      selectedDesignId: designId,
      selectedPart: partName,
      selectedElementName: attributeName,
      selectedIndex: index ?? "",
    });
  };

  const { fetchProductDefinition } = useDesignFetcher();

  const updateProductDef = useCallback(
    async (designFamily?: DesignFamily, designProduct?: DesignProduct) => {
      try {
        if (!designFamily || !designProduct) {
          return;
        }
        setIsLoading(true);
        const res = await fetchProductDefinition(designFamily, designProduct);
        setProductDefinition(res.productDefinition);

        return {
          tagOptions: res.tagOptions,
          repeatingPatternsOptions: res.repeatingPatternsOptions,
        };
      } catch (e: any) {
        if (e.response && e.response.data.errorKey) {
          setLoadingFailedErrorKey(e.response.data.errorKey);
        } else {
          setLoadingFailedErrorKey("unknown");
        }

        setProductDefinition(null);
        setLoadingFailed(true);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchProductDefinition]
  );

  const updateLocalDesignState = useCallback(async () => {
    try {
      const { data } = await DesignStateService.getDesignState(designId);
      const productRef = await updateProductDef(
        data.design.family,
        data.design.product
      );
      setDesignState(data);
      setTagOptions(productRef?.tagOptions);
      setRepeatingPatternOptions(productRef?.repeatingPatternsOptions);
    } catch (e: any) {
      if (e.response && e.response.data.errorKey) {
        setLoadingFailedErrorKey(e.response.data.errorKey);
      } else {
        setLoadingFailedErrorKey("unknown");
      }
      setDesignState(null);
      setTagOptions(null);
      setRepeatingPatternOptions(null);
      setLoadingFailed(true);
    } finally {
      setIsLoading(false);
    }
  }, [designId, updateProductDef]);

  useEffect(() => {
    updateLocalDesignState();
  }, [updateLocalDesignState]);

  // Every time an update is done, verify everything is still available.
  useEffect(() => {
    if (!designState || !designState.design) {
      return;
    }

    // Check if the design contains color or styles no longer available.
    const deprecatedItems = [];
    const colorBleed = [];
    setDesignWarning(false);
    setMigrationNeeded(!!designState.design.needsMigration);
    setColorWarning(
      !!designState.design?.containsColorsLikelyToBleedOnEachOther
    );

    for (const part of designState.design.parts) {
      for (const element of part.elements) {
        if (element.style.deprecated) {
          setDesignWarning(true);
          deprecatedItems.push({
            type: DEPRECATION_TYPE_ENUM.DEPRECATED_STYLE,
            partName: part.name,
            elementName: element.name,
            styleName: element.style.name,
          });
        }
        for (const layer of element.style.layers) {
          if (layer.deprecated) {
            setDesignWarning(true);
            deprecatedItems.push({
              type: DEPRECATION_TYPE_ENUM.DEPRECATED_COLOR,
              partName: part.name,
              elementName: element.name,
              styleName: element.style.name,
              layerName: layer.name,
            });
          }
          // When there is bleeding, flag all the layers that either bleed or are bled on.
          if (
            designState.design?.containsColorsLikelyToBleedOnEachOther &&
            (layer.containsColorsThatTendsToBeBledOn ||
              layer.containsColorsThatTendsToBleed)
          ) {
            colorBleed.push({
              bleeding: layer.containsColorsThatTendsToBleed,
              bledOn: layer.containsColorsThatTendsToBeBledOn,
              partName: part.name,
              elementName: element.name,
              styleName: element.style.name,
              layerName: layer.name,
            });
          }
        }
      }
    }
    setDeprecatedDesignItems(deprecatedItems);
    setColorBleedingItems(colorBleed);
  }, [
    designState,
    setDesignWarning,
    setMigrationNeeded,
    setDeprecatedDesignItems,
  ]);

  const onMessage = useCallback(
    (message: any) => {
      const response = JSON.parse(message.data);
      //console.debug("onMessage from websocket received", response);
      if (response.action === "UpdateDesign") {
        //console.debug("Message 'UpdateDesign' from websocket received", response);

        const newDesignStateCompressedBased64 = response.designState;
        const newDesignStateCompressedBuffer = Buffer.from(
          newDesignStateCompressedBased64,
          "base64"
        );

        const inflatedBuffer = new InflateStream(
          newDesignStateCompressedBuffer
        );
        const decompressed = inflatedBuffer.decompress(
          newDesignStateCompressedBuffer
        );
        const buffer = Buffer.from(decompressed.buffer);
        const str = buffer.toString("utf8");
        const newDesignState = JSON.parse(str.replace(/\0+$/, ""));

        //Update only if timestamp is newer than our current designstate timestamp
        //FIXME - check with Pascal
        if (newDesignState.latestImage > (designState?.latestImage ?? 0)) {
          //console.debug("Design state received is newer than what we have locally, so we  update the local design state");
        } else {
          //console.debug("Design state received is older (or equal) than what we have locally, so we do not need to update the local design state");
          return;
        }

        dispatch({
          type: DESIGNS_ACTION_ENUM.UPDATE_DESIGN_THUMBNAIL,
          payload: {
            id: designId,
            images: newDesignState.images,
          },
        });

        setDesignState(newDesignState);
      }
    },
    [dispatch, setDesignState, designState, designId]
  );

  useEffect(() => {
    if (!socketRef) {
      console.log("socketRef is null");
      return;
    }

    //console.debug("Design state - adding message listener");
    socketRef.current.addEventListener("message", onMessage);

    //copyCurrent is for the return when component is destroyed, so if the ref changed, that old copy will stay in the
    const currentCopy = socketRef.current;

    return () => {
      //console.debug("Design state  - Removing Event listener for messages");
      currentCopy.removeEventListener("message", onMessage);
    };
  }, [onMessage, socketRef]);

  const getProductPartsDefinition = useCallback(() => {
    if (isLoading || !designState || !productDefinition) {
      return [];
    }
    return productDefinition.parts;
  }, [designState, productDefinition, isLoading]);

  const partsDefinition = getProductPartsDefinition();

  const getFabricsDefinition = useCallback(() => {
    if (isLoading || !designState || !productDefinition) {
      return [];
    }
    return productDefinition.fabrics;
  }, [designState, productDefinition, isLoading]);

  const fabricsDefinition = getFabricsDefinition();

  const getProductAvailableDecorationsDefinition = useCallback(() => {
    if (isLoading || !designState || !productDefinition) {
      return [];
    }
    return productDefinition.decorations;
  }, [designState, productDefinition, isLoading]);

  const availableDecorationsDefinition =
    getProductAvailableDecorationsDefinition();

  const attributeStyles = useAttributeStyles(
    partsDefinition,
    selection,
    isLoading
  );

  const getDesignValueOfSelectedElement = useCallback(() => {
    if (!designState || !designState.design || !designState.design.parts) {
      return undefined;
    }
    const part = designState.design.parts.find(
      (p) => p.name === selection.selectedPart
    );
    if (!part) {
      return undefined;
    }
    const attribute = part.elements.find(
      (e) => e.name === selection.selectedElementName
    );
    if (!attribute) {
      return undefined;
    }
    return attribute;
  }, [designState, selection.selectedPart, selection.selectedElementName]);

  const designValueOfSelectedElement = getDesignValueOfSelectedElement();

  const getSelectedPartElementDef = useCallback(() => {
    if (selection.selectedPart === "Decoration") {
      return undefined;
    }

    const partDefFound = partsDefinition?.find(
      (partDef) => partDef.name === selection.selectedPart
    );

    if (!partDefFound) {
      return undefined;
    }

    const elementDefFound = partDefFound.elements.find(
      (a) => a.name === selection.selectedElementName
    );

    //FIXME: For now we add all the fabrics (colors) definition to the elementDef, so it's simple to use when needed
    //FIXME: but we should create another mechanism to fetch fabrics once and store in memory and available in a store
    if (elementDefFound) {
      elementDefFound.fabrics = fabricsDefinition;
    }

    return elementDefFound;
  }, [
    selection.selectedElementName,
    selection.selectedPart,
    partsDefinition,
    fabricsDefinition,
  ]);

  const selectedPartElementDef = getSelectedPartElementDef();

  const getDesignPricing = useCallback(() => {
    if (isLoading || !designState || !designState.customizationPricing) {
      return;
    }

    return designState.customizationPricing;
  }, [isLoading, designState]);
  const designPricing = getDesignPricing();

  const getDeliveryTime = useCallback(() => {
    if (isLoading || !designState || !designState.deliveryTime) {
      return;
    }

    return designState.deliveryTime;
  }, [isLoading, designState]);
  const deliveryTime = getDeliveryTime();

  const getSiblingProducts = useCallback(() => {
    if (isLoading || !designState || !designState.siblingProducts) {
      return;
    }

    return designState.siblingProducts;
  }, [isLoading, designState]);
  const siblingProducts = getSiblingProducts();

  const getPricingDef = useCallback(() => {
    if (isLoading || !designState || !productDefinition) {
      return;
    }

    return productDefinition.pricing;
  }, [isLoading, designState, productDefinition]);

  const pricingDef = getPricingDef();

  const getSizesOption = useCallback(() => {
    if (isLoading || !designState || !productDefinition) {
      return;
    }

    return productDefinition.sizes;
  }, [isLoading, designState, productDefinition]);

  const sizesOption = getSizesOption();

  const updateDesign = useCallback(
    async (payload: {
      action: DESIGN_CHANGE_ENUM;
      designId?: string;
      color?: any;
      group?: string;
      name?: string;
      transparent?: any;
      indexOfColor?: number;
      decorationId?: string;
      tagName?: string;
      colorName?: string;
      repeatingDetails?: any;
      positionName?: string;
      active?: boolean;
      textureName?: string;
      size?: any;
      partName?: string;
      assetId?: string;
      elementName?: string;
      assetVersionId?: string;
      styleName?: string;
      type?: string;
      lastStyleName?: string;
      layerName?: string;
      colorComboName?: string;
      lastColor?: string;
      model?: DesignProduct;
    }) => {
      try {
        console.time("calling updateDesign");
        const resUpdate = await DesignStateService.updateDesign(
          payload,
          designState?.design?.product
        );
        console.timeEnd("calling updateDesign");
        return resUpdate;
      } catch (err: any) {
        console.error("Error sending update of design state", err);
        //FIXME: DP Not sure we should have toast here, at the AxiosInterceptor or somewhere else
        if (err.response && err.response.data && err.response.data.errorKey) {
          toast.error(t(`toast.${err.response.data.errorKey}`), {
            toastId: err.response.data.errorKey,
          });
        }
        updateLocalDesignState();
      }
    },
    [updateLocalDesignState, t, designState]
  );

  const onDesignChange = useCallback(
    async (action: DESIGN_CHANGE_ENUM, data: any) => {
      setIsUpdating(true);

      let updateDesignRes = null;
      let resetProductDef = null;

      switch (action) {
        case DESIGN_CHANGE_ENUM.REFRESH: {
          updateDesignRes = await updateDesign({
            action,
            designId,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.ADD_DECORATION: {
          updateDesignRes = await updateDesign({
            action,
            group: data.group,
            name: data.name,
            decorationId: data.decorationId,
            designId,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.ON_REMOVE: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            decorationId: data.decorationId,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.ON_REINITIALIZE: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            decorationId: data.decorationId,
            name: data.name,
            group: data.group,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.UPDATE_PRINT_COLOR: {
          updateDesignRes = await updateDesign({
            action,
            designId,
            color: data.color,
            transparent: data.transparent,
            indexOfColor: data.index,
            decorationId: data.decorationId,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_DECORATION_TAG: {
          updateDesignRes = await updateDesign({
            action,
            designId,
            decorationId: data.decorationId,
            tagName: data.tagName,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_DECORATION_TAG_BACKGROUND_COLOR: {
          updateDesignRes = await updateDesign({
            action,
            designId,
            decorationId: data.decorationId,
            colorName: data.colorName,
          });
          break;
        }
        case DESIGN_CHANGE_ENUM.SET_DECORATION_EMBROIDERY_PATCH_BACKGROUND_COLOR: {
          updateDesignRes = await updateDesign({
            action,
            designId,
            decorationId: data.decorationId,
            colorName: data.colorName,
          });
          break;
        }
        case DESIGN_CHANGE_ENUM.SET_DECORATION_EMBROIDERY_PATCH_STITCH_COLOR: {
          updateDesignRes = await updateDesign({
            action,
            designId,
            decorationId: data.decorationId,
            colorName: data.colorName,
          });
          break;
        }
        case DESIGN_CHANGE_ENUM.SET_REPEATING_PATTERN_DETAILS: {
          updateDesignRes = await updateDesign({
            action,
            designId,
            decorationId: data.decorationId,
            repeatingDetails: data.repeatingDetails,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_DECORATION_LOCATION: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            decorationId: data.decorationId,
            positionName: data.positionName,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_DECORATION_ACTIVE: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            decorationId: data.decorationId,
            active: data.active,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_DECORATION_TAG_TEXTURE: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            decorationId: data.decorationId,
            textureName: data.textureName,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_SIZE: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            decorationId: data.decorationId,
            size: data.size,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_STYLE: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            partName: selection.selectedPart,
            elementName: selection.selectedElementName,
            styleName: data.styleName,
            lastStyleName: data.lastStyleName,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_MODEL: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            model: data.model,
          });
          resetProductDef = true;
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_COLOR: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            partName: selection.selectedPart,
            elementName: selection.selectedElementName,
            styleName: data.styleName,
            layerName: data.layerName,
            colorName: data.colorName,
            lastColor: data.lastColor,
            active: data.active,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_COLOR_COMBO: {
          updateDesignRes = await updateDesign({
            designId,
            action,
            partName: selection.selectedPart,
            elementName: selection.selectedElementName,
            styleName: data.styleName,
            colorComboName: data.colorComboName,
          });
          break;
        }

        case DESIGN_CHANGE_ENUM.SET_DECORATION_ASSET: {
          updateDesignRes = await updateDesign({
            action,
            designId,
            decorationId: data.decorationId,
            assetId: data.assetId,
            assetVersionId: data.assetVersionId,
            name: data.name,
            type: data.type,
          });
          break;
        }
        case DESIGN_CHANGE_ENUM.CHANGE_DECORATION_TYPE: {
          updateDesignRes = await updateDesign({
            action,
            designId,
            decorationId: data.decorationId,
            assetId: data.assetId,
            assetVersionId: data.assetVersionId,
            name: data.name,
            type: data.type,
          });
          break;
        }
        default: {
          console.error("action not supported!", action);
          break;
        }
      }

      if (updateDesignRes && updateDesignRes.data) {
        const newDesignState = updateDesignRes.data;
        dispatch({
          type: DESIGNS_ACTION_ENUM.UPDATE_DESIGN_THUMBNAIL,
          payload: {
            id: designId,
            images: newDesignState.images,
          },
        });
        //TODO: We should probably just use the newDesignState
        setDesignState(newDesignState);
        if (resetProductDef) {
          updateProductDef(
            newDesignState.design.family,
            newDesignState.design.product
          );
        }
      }
      setIsUpdating(false);
    },
    [
      updateDesign,
      designId,
      selection.selectedPart,
      selection.selectedElementName,
      dispatch,
      updateProductDef,
    ]
  );

  /**
   * Function called when a decoration is changing type (Like from print to detailedprint)
   */
  const onAssetTypeChange = useCallback(
    async ({
      decorationId,
      assetId,
      assetType,
      name,
      etag = "",
    }: {
      decorationId: string;
      assetId: string;
      assetType: string;
      name: string;
      etag: string;
    }) => {
      //Create the asset type
      const { assetVersionId, errorKey } = await ImageService.createAsset(
        assetId,
        assetType,
        etag
      );

      if (errorKey) {
        return { errorKey };
      }

      return await onDesignChange(DESIGN_CHANGE_ENUM.CHANGE_DECORATION_TYPE, {
        designId,
        decorationId,
        assetId,
        assetVersionId,
        type: assetType,
        name,
      });
    },
    [onDesignChange, designId]
  );

  return {
    attributeStyles,
    designDecorationsFromDesignState: designState?.design?.decorations,
    designFromDesignState: designState?.design,
    images: designState?.images,
    designWarning,
    migrationNeeded,
    colorWarning,
    deprecatedDesignItems,
    colorBleedingItems,
    isLoading,
    hasLoadingFailed,
    loadingFailedErrorKey,
    isUpdating,
    onDesignChange,
    onAssetTypeChange,
    partsDefinition,
    availableDecorationsDefinition,
    designValueOfSelectedElement,
    selectedPartElementDef,
    designPricing,
    deliveryTime,
    siblingProducts,
    pricingDef,
    sizesOption,
    tagOptions, // TODO: EVALUATE IF STILL USED
    repeatingPatternOptions, // TODO: Remove. Unused
    onSelectionChange,
    selection,
  };
};

interface CursorPointerWebsocketMessageResponse {
  action: string;
  connectedUsers: {
    coordinates: { x: number; y: number };
    designId: string;
    latestUpdate: number;
    userId: string;
    color: string;
  }[];
}

interface CursorPointerWebsocketUserConnected {
  color: any;
  id: string;
  initials: string | undefined;
}

interface CursorPointerWebsocketProps {
  teammates: Teammate[];
  designId?: string;
  userId: string;
}

export const useCursorPointerWebsocket = ({
  teammates,
  designId,
  userId,
}: CursorPointerWebsocketProps) => {
  const { moment } = useMoment();
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [usersOfTeam, setUsersOfTeam] = useState<Teammate[]>([]);
  const [usersConnected, setUsersConnected] = useState<
    CursorPointerWebsocketUserConnected[]
  >([]);
  const [cursorsOfUsersConnected, setCursorsOfUsersConnected] = useState<
    CursorPointerWebsocketMessageResponse["connectedUsers"]
  >([]);

  //const windowSize = useWindowSize();
  const previewContainerRef = useRef<HTMLDivElement>(null);

  const socketRef = useRef<any>(null);

  //Sending our own mouse coordinates
  const sendCoordinates = useCallback(
    ({ x, y }: { x: number; y: number }) => {
      if (websocketConnected && socketRef.current) {
        socketRef.current.send(
          JSON.stringify({
            action: "movement",
            coordinates: { x, y },
            timestamp: new Date(),
          })
        );
      }
    },
    [websocketConnected, socketRef]
  );

  const onCursorMove = throttle(
    (e) => {
      if (previewContainerRef.current) {
        const xRelativePosInsidePreviewContainer =
          (e.pageX - previewContainerRef.current.getBoundingClientRect().left) /
          previewContainerRef.current.getBoundingClientRect().width;
        const yRelativePosInsidePreviewContainer =
          (e.pageY - previewContainerRef.current.getBoundingClientRect().top) /
          previewContainerRef.current.getBoundingClientRect().height;

        //Sending as % because the viewport of the other players may be different sizes than ours.
        sendCoordinates({
          x: xRelativePosInsidePreviewContainer,
          y: yRelativePosInsidePreviewContainer,
        });
      }
    },
    32,
    { leading: true }
  );

  //Getting one time the users associated to the project (until the projectid changed)
  //Caveat: if a user is added to the project during design in the studio, that user won't be in that list
  const initUsersOfTeam = useCallback(async () => {
    if (!teammates) {
      return;
    }

    setUsersOfTeam(teammates);
  }, [teammates]);

  useEffect(() => {
    initUsersOfTeam();
  }, [initUsersOfTeam]);

  useEffect(() => {
    if (!previewContainerRef.current) {
      return;
    }
    //console.debug("Cursor - Adding mousemouve event listener");
    previewContainerRef.current.addEventListener("mousemove", onCursorMove);
    const copyRef = previewContainerRef.current;
    return () => {
      //console.debug("Cursor - Removing mousemouve event listener");
      copyRef.removeEventListener("mousemove", onCursorMove);
    };
  }, [onCursorMove, previewContainerRef]);

  //const onUpdate = useCallback(
  const onMessage = useCallback(
    (message: any) => {
      const res = JSON.parse(
        message.data
      ) as CursorPointerWebsocketMessageResponse;

      if (res.action === "UpdateConnectedUsers") {
        let connectedUsers: CursorPointerWebsocketMessageResponse["connectedUsers"] =
          [];
        res.connectedUsers
          .filter((c) => c.designId === designId)
          .sort((a, b) => {
            const aDate = moment(a.latestUpdate);
            const bDate = moment(b.latestUpdate);
            if (aDate < bDate) return 1;
            if (aDate > bDate) return -1;

            return 0;
          })
          .forEach((c) => {
            if (!connectedUsers.find((u) => u.userId === c.userId)) {
              connectedUsers.push(c);
            }
          });
        connectedUsers = connectedUsers.map((c, index) => ({
          ...c,
          color: `hsl(${(index * 223) % 360}, 40%, 60%)`,
        }));

        //First need to check if the users or the cursors have changed, because if they didn't, no need to mutate the states
        //TODO This is a basic comparison using JSON.stringify(, we should probably check a more detailed one
        const newUsersList = connectedUsers.map((c: any) => ({
          color: c.color,
          id: c.userId,
          initials: usersOfTeam.find((u) => u.id === c.userId)?.first_name[0],
        }));

        if (JSON.stringify(newUsersList) !== JSON.stringify(usersConnected)) {
          //console.debug("Setting new users's list", newUsersList);
          setUsersConnected(newUsersList);
        }

        //We do not show the cursor of the current user
        //nor do we show cursors of users that did not yet set their cursor
        const newCursorsList = connectedUsers.filter(
          (c: any) => c.userId !== userId && c.coordinates
        );

        if (
          JSON.stringify(newCursorsList) !==
          JSON.stringify(cursorsOfUsersConnected)
        ) {
          //console.debug("Setting cursors of connected users", newCursorsList);
          setCursorsOfUsersConnected(newCursorsList);
        }
      }
    },
    [designId, userId, usersOfTeam, cursorsOfUsersConnected, usersConnected]
  );

  //Create the websocket to be used to send and get messages from the server
  useEffect(() => {
    //console.debug("useWebSocket - creating new websocket object");
    //socketRef.current = new WebSocket(process.env.REACT_APP_WEB_SOCKET);
    socketRef.current = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEB_SOCKET}?userId=${userId}&designId=${designId}`,
      [],
      { debug: false }
    );

    const onOpen = () => {
      console.debug("OnOpen websocket, set connected = true");
      setWebsocketConnected(true);
    };

    const onClose = () => {
      console.debug("OnClose websocket, set connected = false");
      //setWebsocketConnected(false); //
      //socketRef.current = null;//No need to set to null since the reconnecting websocket is always there
    };

    //console.debug("Cursor - Adding Event listener for open and close");
    socketRef.current.addEventListener("open", onOpen);
    socketRef.current.addEventListener("close", onClose);

    //CopyCurrent is used for the onClose and the return when component is destroyed
    const copyCurrent = socketRef.current;

    return () => {
      console.debug("Cursor - Removing Event listener for open");
      copyCurrent.removeEventListener("open", onOpen);
      copyCurrent.close();
      //copyCurrent.removeEventListener("close", onClose);
    };
  }, [userId, designId]);

  useEffect(() => {
    if (!socketRef.current) {
      console.log("socketRef.current is null");
      return;
    }

    //console.debug("Cursor - Adding Event listener for messages");
    socketRef.current.addEventListener("message", onMessage);

    //copyCurrent is for the return when component is destroyed, so if the ref changed, that old copy will stay in the function
    const copyCurrent = socketRef.current;
    return () => {
      //console.debug("Cursor - Removing Event listener for messages");
      copyCurrent.removeEventListener("message", onMessage);
    };
  }, [socketRef, onMessage]);

  return {
    cursorsOfUsersConnected,
    previewContainerRef,
    usersConnected,
    socketRef,
    websocketConnected,
  };
};

export const useTokenInfo = (token?: string) => {
  const navigate = useNavigate();
  const [tokenInfo, setTokenInfo] = useState<any | null>(null);

  useEffect(() => {
    const fetchTokenInfo = async (token?: string) => {
      if (!token) {
        return;
      }

      try {
        const {
          data: { data: info },
        } = await TeamService.getTokenInfo(token);

        setTokenInfo(info);
      } catch (error) {
        navigate(ROUTES.LOGIN, {
          state: { inviteLinkExpired: true },
        });
      }
    };
    fetchTokenInfo(token);
  }, []);

  return tokenInfo;
};

// TODO check invite_team_token

import { CountryCode } from "libphonenumber-js";

//Common

export type Currency = "cad" | "usd";
export type Language = "fr" | "en";
export type OrderType = "preorder" | "order";

export type Response<T> = {
  data: T;
  message: string;
  status: string;
};

export interface User {
  email: string;
  first_name: string;
  id: string;
  language: string;
  last_name: string;
  username: string;
}

export interface UserOnboardingInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  optNewsletter?: boolean;
  language?: string;
  forWho?: string;
  projectSize?: string;
  teamName?: string;
  eventDate?: string;
  campaign?: string;
  inviteToken?: string;
}

export interface Project {
  coverUuid?: string;
  cover_uuid?: string;
  createdAt: Date;
  created_at: Date;
  deletedAt?: Date;
  deleted_at?: Date;
  iconUuid?: string;
  icon_uuid?: string;
  id: string;
  marketingEstimatedProjectSize: string;
  marketingEventDate?: Date;
  marketingForWho: string;
  marketing_estimated_project_size: string;
  marketing_event_date?: Date;
  marketing_for_who: string;
  name: string;
  shareableToken: string;
  shareable_token: string;
  teamId: string;
  team_id: string;
  updatedAt: Date;
  updated_at: Date;
  userId?: string;
  user_id?: string;
}

export interface ProjectWithDesigns extends Project {
  designs: (DesignFromDB & { images: string[] })[];
}

export interface Role {
  role: string;
}

export interface Product {
  family: DesignFamily;
  product: DesignProduct;
  labelTag: string;
  imgName: string;
  minQty: number;
  comingSoon?: boolean;
}

export interface Team {
  created_at: Date;
  deleted_at?: Date;
  id: string;
  name: string;
  teammates: Teammate[];
  updated_at: Date;
}

export interface Teammate {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  roles: Role[];
  username?: string;
  imageUrl?: string;
}

export interface FlattenTeam {
  teamId: string;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  userId: string;
}

export interface UserInfo {
  canAddArtwork: boolean;
  canAddDesign: boolean;
  canAddProject: boolean;
  canAddTeam: boolean;
  canDeleteArtwork: boolean;
  canDeleteDesign: boolean;
  canDeleteProject: boolean;
  canDeleteTeam: boolean;
  canMoveDesign: boolean;
  canRenameDesign: boolean;
  canRenameProject: boolean;
  canRenameTeam: boolean;
  canUpdateArtworkColor: boolean;
  canUpdateArtworkPosition: boolean;
  canUpdateDesignColors: boolean;
  canUpdateDesignStyles: boolean;
  email: string;
  exp: number;
  firstName: string;
  iat: number;
  id: string;
  isAdmin: boolean;
  isDesigner: boolean;
  isUser: boolean;
  language: string;
  lastName: string;
  optNewsletter: boolean;
  imageUrl: string;
  roles: Role[];
}

export interface AddressFormType {
  organization: string;
  firstName: string;
  lastName: string;
  address: string;
  appartment: string;
  city: string;
  country: CountryCode;
  state: string;
  zipcode: string;
  phone: string;
  // phoneExt: string;
  email: string;
}

export interface AddressType {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  organization: string;
  phone: string;
  phoneExt: string;
  address: {
    city: string;
    country: CountryCode;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  };
}

export interface Size {
  name: string;
  displayLabel?: string;
  qty?: number;
}

export interface Color {
  name: string;
  hex: string;
  label: string;
}

export interface Image {
  url: string;
  side: string;
  imageS3Bucket?: string;
  path?: string;
}

export interface FileType {
  extensions: string[];
  name: string;
}

export interface DeliveryTime {
  minWeeks: number;
  maxWeeks: number;
}

export interface DesignFamily {
  name: string;
  version: string;
}

export interface DesignProduct {
  name: string;
  version: string;
}

export interface DisplayName {
  fr: string;
  en: string;
}

export enum GLASS_MAGNIFIER_BACKGROUND_COLORS {
  LIGHT = "#DADADA",
  DARK = "#555555",
  WHITE = "#FFFFFF",
}

///////////////////////// Specs
export interface PricingRange {
  qty: number;
  price: number;
  shipping: number;
}

export interface PricePerSize {
  sizeName: string;
  price: number;
  qtyThreshold: number;
  min: number;
  max: number;
}

export interface CollectSizeOrder {
  created_at: Date;
  deletedAt?: Date;
  designId: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  quantity: number;
  size: string;
  updatedAt: Date;
}

export interface CollectSizeOrderGrouped {
  email: string;
  first_name: string;
  last_name: string;
  designId: string;
  sizes: {
    sizeName: string;
    quantity: number;
  }[];
  created_at: Date;
}

export interface Specs {
  families: SpecsFamily[];
  products: SpecsProduct[];
  fabrics: SpecsFabric[];
  decorations: SpecsDecoration[]; // TODO valid if its still needed
}

export interface SpecsFamily {
  name: string;
}

export interface SpecsProduct {
  name: string;
  displayName: string;
  versions: SpecsProductVersion[];
}

export interface SpecsFabric {
  name: string;
  defaultColor: Color;
  colors: Color[];
}

export interface SpecsDecoration {
  id: string;
  name: string;
}

export interface SpecsProductVersion {
  name: string;
  deliveryTime: DeliveryTime;
  sizes: Size[];
  pricing: SpecsProductPricing;
}

export interface SpecsProductSpecific {
  product: SpecsProductVersion;
  fabrics: SpecsFabric[];
  decorations: SpecsDecoration[];
  sizes: Size[];
  pricing: SpecsProductPricing;
}

export interface SpecsProductPricing {
  freeCredits: number;
  minQty: number;
  pricingCurrency: SpecsProductPricingCurrency;
}

export type SpecsProductPricingCurrency = {
  [key in Currency]: {
    priceOfExtraCredit: number;
    pricingRange: PricingRange[];
    pricePerSize: PricePerSize[];
  };
};

// Order

export interface Invoice {
  chargeId: string;
  checkoutSessionId: string;
  currency: Currency;
  customer: AddressType;
  discount: number;
  discounts: any[];
  hasReachedMaximum: boolean;
  invoiceNumber?: string;
  invoice_number?: string;
  locale: Language;
  paymentIntentId: string;
  receiptUrl: string;
  shipping: number;
  subtotal: number;
  tax: number;
  taxes: any[];
  total: number;
}

export interface EventHistory {
  name: string;
  data: EventHistoryData;
}

export interface EventHistoryData {
  new?: EventHistoryActionData;
  old?: EventHistoryActionData;
}

export interface EventHistoryActionData {
  action: string;
  data: any;
  // {
  //   color: string;
  //   decorationId: string;
  //   index: number;
  //   transparent: boolean;
  //   discounts: number;
  //   currency: Currency;
  //   shipping: AddressType;
  //   purchasedDesignId: string;
  //   designId: string;
  //   sizes: Size[];
  //   status: string;
  //   externalInvoiceString: string;
  //   billing: AddressType;
  //   purchasedItemId: string;
  //   purchasedItem: CartItem;
  //   preorder: PreOrder;
  // };
}

export interface History {
  User: User;
  UserId: string;
  created_at: Date;
  deleted_at?: Date;
  event: EventHistory;
  id: string;
  preorder_id: string;
  updated_at: Date;
  user_id: string;
}

export interface OrderOrPreorder {
  createdAt: Date;
  created_at: Date;
  id: string;
}

export interface Order extends OrderOrPreorder {
  status:
    | "intent"
    | "purchased"
    | "cancelled"
    | "inreviewbycustomer"
    | "inreviewbydesigner"
    | "shipped"
    | "closed";
}

export interface PreOrder extends OrderOrPreorder {
  status:
    | "intent"
    | "purchased"
    | "cancelled"
    | "inreviewbycustomer"
    | "inreviewbydesigner"
    | "shipped"
    | "delivered"
    | "inproduction";
}

export interface OrderDetail {
  cartItems: CartItem[];
  history: History[];
  invoice: Invoice;
  preorder?: PreOrder;
  order?: Order;
  promoCode: string;
  purchasedItems: PurchasedItem[];
  shipping: AddressType;
  techpack: Techpack;
  user: User;
}

export interface PurchasedItem {
  deprecatedDesign: boolean;
  designId: string;
  designName: string;
  id: string;
  original_design_id: string;
  pricing: CartItemPricing;
  productImages: Image[];
  productName: string;
  projectId: string;
  projectName: string;
  sizes: Size[];
  teamId: string;
  teamName: string;
  versionName: string;
}

export interface Techpack {
  created_at: Date;
  customer_number?: string;
  deleted_at?: Date;
  generated_at?: Date;
  id: string;
  order_id: string;
  order_type: OrderType;
  project_number?: string;
  updated_at: Date;
}

export interface AdminPurchasedOrderType {
  createdAt: Date;
  details: OrderDetail;
  type: OrderType;
}

//Pricing

export interface DesignPricing {
  creditsUsedInDesign: number;
  customizationsListWithCredits: {
    credits: number;
    displayLabels: string[];
    explanation: string;
    fabric: string;
    id: string;
    name: string;
    type: string;
  }[];
}

// Design State

export interface DesignFromGenerator {
  customizationPricing: DesignPricing;
  deliveryTime: DeliveryTime;
  design: Design;
  designId: string;
  designWithColors: DesignWithColors;
  family: DesignFamily;
  imageUUID: string;
  images: Image[];
  latestImage: number;
  latestUpdate: number;
  product: DesignProduct;
  siblingProducts: DesignStateSiblingProduct[];
  version: number;
  errorKey?: string;
}

export type DesignState = DesignFromGenerator & DesignFromDB;
export type DesignStateWithProductName = DesignState & {
  product: { name: string };
};

export interface DesignStateSiblingProduct {
  icon: string;
  product?: DesignProduct;
  name: string;
  version: string;
}

export interface DesignFromDB {
  collectableSince?: string;
  collectable_since?: string;
  createdAt: Date;
  created_at: Date;
  deletedAt?: Date;
  deleted_at?: Date;
  description?: string;
  id: string;
  name: string;
  projectId: string;
  project_id: string;
  updatedAt: Date;
  updated_at: Date;
  isFavorite?: boolean;
}

export interface Design {
  containsColorsLikelyToBleedOnEachOther: boolean;
  containsColorsThatTendsToBeBledOn: boolean;
  containsColorsThatTendsToBleed: boolean;
  decorations: DesignDecoration[];
  deprecated: boolean;
  family: DesignFamily;
  needsMigration: boolean;
  parts: DesignPart[];
  product: DesignProduct;
}

export interface DesignDecoration {
  active: boolean;
  assetId: string;
  assetSize: DesignDecorationAssetSize;
  assetVersionId: string;
  group: string;
  id: string;
  inError: boolean;
  inWarning: boolean;
  isVectorImage: boolean;
  latestUpdate: number;
  listOrder: number;
  location: DesignDecorationLocation;
  message: string;
  name: string;
  originalHeight: number;
  originalTrimmedHeight: number;
  originalTrimmedWidth: number;
  originalWidth: number;
  priority: number;
  ratio: string;
  repeatingDetails: DesignDecorationRepeatingDetails;
  tagDetails: DesignDecorationTagDetails;
  type: string;
  warningMessages: string[];
}

export interface DesignDecorationAssetSize {
  availableSizes: DesignDecorationAssetSizeSize[];
  inWarning: boolean;
  selectedSize: DesignDecorationAssetSizeSize;
  warningMessages: string[];
}

export interface DesignDecorationAssetSizeSize {
  drivingSide: string;
  heightInInches: number;
  id: number;
  scale: number;
  widthInInches: number;
}

export interface DesignDecorationLocation {
  availableLocations: DesignDecorationLocationType[];
  selectedLocation: DesignDecorationLocationType;
}

export interface DesignDecorationLocationType {
  allowedDecorationTypes: string[];
  coverArea: boolean;
  designTemplates: DesignDecorationLocationDesignTemplate[];
  dropdownLabel: string;
  elementName: string;
  groupName: string;
  imageTooSmall: boolean;
  inUse: boolean;
  increment: number;
  maxHeightInches: number;
  maxWidthInches: number;
  minHeightInches: number;
  minTagHeightInches: number;
  minTagWidthInches: number;
  minWidthInches: number;
  partName: string;
  planeAngle: number;
  positionId: string;
  positionName: string;
  spotTaken: boolean;
  styleName: string;
}

export interface DesignDecorationLocationDesignTemplate {
  name: string;
  url: string;
  // {
  //     name: "canvasTemplate",
  //     url: "" // TODO: Canvas link
  // },
}

export interface DesignDecorationRepeatingDetails {
  horizontalOffsetInches: number;
  horizontalSpacingInches: number;
  maxImageHeightInches: number;
  maxImageWidthInches: number;
  patternName: string;
  verticalOffsetInches: number;
  verticalSpacingInches: number;
}

export interface DesignDecorationTagDetails {
  availableTagNames: string[];
  backgroundColorName: string;
  backgroundFabricName: string;
  stitchColorName: string;
  stitchFabricName: string;
  tagName: string;
  textureName: string;
}

export interface DesignPart {
  elements: DesignPartElement[];
  inheritFrom: string;
  mainElement: string;
  name: string;
}

export interface DesignPartElement {
  active: boolean;
  enabled: boolean;
  name: string;
  reasonForBeingDisabled: DesignPartElementDisabledReason[];
  style: DesignPartElementStyle;
}

export interface DesignPartElementDisabledReason {
  msg: string;
  disabledByPart: string;
  disabledByElement: string;
  disabledByStyle: string;
}

export interface DesignPartElementStyle {
  layers: DesignPartElementStyleLayer[];
  name: string;
  deprecated?: boolean;
}

export interface DesignPartElementStyleLayer {
  active: boolean;
  colorName: string;
  containsColorsThatTendsToBeBledOn?: boolean;
  containsColorsThatTendsToBleed?: boolean;
  credits: number;
  deprecated: boolean;
  name: string;
  type: string;
}

export interface DesignWithColors {
  decorations: DesignDecoration[];
  family: DesignFamily;
  parts: DesignPart[];
  product: DesignProduct;
}

// Product Definition

export interface ProductDefinition {
  availableDecorationTypes: { name: string }[];
  availableImageSides: { name: string }[];
  decorations: ProductDefinitionDecoration[];
  deliveryTime: DeliveryTime;
  fabrics: ProductDefinitionFabric[];
  factory: string;
  generator: ProductDefinitionGenerator;
  labels: ProductDefinitionLabel[];
  name: string;
  parts: ProductDefinitionPart[];
  positionGroups: ProductDefinitionPositionGroup[];
  positions: ProductDefinitionPosition[];
  pricing: SpecsProductPricing;
  productSex: string;
  sizes: Size[];
  status: string;
}

export interface ProductDefinitionDecoration {
  adminOnly: boolean;
  behavesLike: string;
  display: ProductDefinitionDecorationDisplay;
  displayName: DisplayName;
  fileTypes: FileType;
  group: string;
  id: string;
  legacyAssetTypes: string[];
  name: string;
  repeatingPatternOptions: ProductDefinitionDecorationPatternOption;
}

export interface ProductDefinitionDecorationDisplay {
  listOrder: number;
  colorImgUrl: string;
}

export interface ProductDefinitionDecorationPatternOption {
  dpi: number;
  logo: ProductDefinitionDecorationPatternOptionLogo;
  output: { width: number; height: number };
  patterns: ProductDefinitionDecorationPatternOptionPattern[];
}

export interface ProductDefinitionDecorationPatternOptionLogo {
  minWidthInches: number;
  maxWidthInches: number;
  minHeightInches: number;
  maxHeightInches: number;
  increment: number;
}

export interface ProductDefinitionDecorationPatternOptionPattern {
  horizontalOffset: ProductDefinitionDecorationPatternOptionPatternType;
  horizontalSpacing: ProductDefinitionDecorationPatternOptionPatternType;
  name: string;
  verticalOffset: ProductDefinitionDecorationPatternOptionPatternType;
  verticalSpacing: ProductDefinitionDecorationPatternOptionPatternType;
}

export interface ProductDefinitionDecorationPatternOptionPatternType {
  minWidthInches: number;
  maxWidthInches: number;
  increment: number;
}

export interface ProductDefinitionFabric {
  colors: ProductDefinitionColor[];
  defaultColor: ProductDefinitionColor;
  description: string;
  factory: string;
  group: string;
  name: string;
}

export interface ProductDefinitionColor {
  chartName: string;
  colorCompatibility: { colorTendsToBeBledOn: boolean };
  column: string;
  fallback: string;
  generatorHex: string;
  hex: string;
  label: string;
  name: string;
  techpackColorName: string;
}

export interface ProductDefinitionGenerator {
  sides: ProductDefinitionGeneratorSide[];
}

export interface ProductDefinitionGeneratorSide {
  alwaysRedraw: boolean;
  highlightOverlayFactor: number;
  highlightTests: string;
  highlightUrl: string;
  imageUrl: string;
  name: string;
  zIndex: number;
  zIndexHighlight: number;
}

export interface ProductDefinitionLabel {
  color: ProductDefinitionColor[];
  description: string;
  position: string;
  type: string;
}

export interface ProductDefinitionPart {
  elements: ProductDefinitionPartElement[];
  inheritFrom: string;
  mainElement: string;
  name: string;
  required: boolean;
}

export interface ProductDefinitionPartElement {
  defaultStyle: string;
  display: ProductDefinitionPartElementDisplay;
  fabrics: ProductDefinitionFabric[];
  inherit: boolean;
  name: string;
  required: boolean;
  styles: ProductDefinitionPartElementStyle[];
}

export interface ProductDefinitionPartElementDisplay {
  menuIconUrl: string;
  displayMethod: string;
  displayable: boolean;
  group?: string;
}

export interface ProductDefinitionPartElementStyle {
  credits: number;
  defaultLayer: string;
  disablingWhenSelected: [];
  display: ProductDefinitionPartElementDisplay;
  layers: ProductDefinitionPartElementStyleLayer[];
  name: string;
  pattern: string;
  positionIds: string[];
}

export interface ProductDefinitionPartElementStyleLayer {
  credits: number;
  display: ProductDefinitionPartElementDisplay;
  excludeFromColorCount: boolean;
  fabricNames: string[];
  generator: ProductDefinitionGenerator;
  layerLabel: string;
  name: string;
  required: boolean;
  techpack: { type: string; position: string };
}

export interface ProductDefinitionPositionGroup {
  mutuallyExclusiveGroupNames: string[];
  name: string;
  unavailableWith: { elementName: string; partName: string; styleName: string };
}

export interface ProductDefinitionPosition {
  allowedDecorationTypes: string[];
  designState: ProductDefinitionPositionDesignState;
  generator: ProductDefinitionPositionGenerator;
  id: string;
  techpack: ProductDefinitionPositionTechpack;
}

export interface ProductDefinitionPositionDesignState {
  dropdownLabel: string;
  groupName: string;
  increment: number;
  maxHeightInches: number;
  maxWidthInches: number;
  minHeightInches: number;
  minWidthInches: number;
  positionName: string;
}

export interface ProductDefinitionPositionGenerator {
  boxHeightInches: number;
  boxWidthInches: number;
  sides: ProductDefinitionPositionGeneratorSize[];
}

export interface ProductDefinitionPositionGeneratorSize {
  h: number;
  name: string;
  w: number;
  x: number;
  y: number;
  zIndex: number;
}

export interface ProductDefinitionPositionTechpack {
  alignmentX: string;
  alignmentY: string;
  assetFilenamePrefix: string;
  conditionalPattern: ProductDefinitionPositionTechpackPattern[];
  patternUrl: string;
  position: string;
}

export interface ProductDefinitionPositionTechpackPattern {
  heightGreaterThan: string;
  patternUrl: string;
}

// Cart
export interface CartError {
  ids: string[];
}

export interface CartItemError {
  id: string;
}

export interface Cart {
  currency?: Currency;
  error?: CartError;
  hasMinimum?: boolean;
  hasMinimumPerSize?: boolean;
  hasReachedMaximum?: boolean;
  items: CartItem[];
  price?: number;
  priceOfDiscounts?: number;
  priceOfShipping?: number;
  priceOfSurcharges?: number;
  priceWithoutDiscountOrSurcharge?: number;
  priceWithoutShipping?: number;
}

export interface CartItem {
  collectedSizes: CollectSizeOrder[];
  createdAt: Date;
  deletedAt?: Date;
  deprecatedDesign: boolean;
  designId: string;
  designName: string;
  id: string;
  orderFrom: string;
  orderId?: string;
  orderItemDetails?: string;
  pricing: CartItemPricing;
  productImages: Image[];
  productName: string;
  projectId: string;
  projectName: string;
  sizes: Size[];
  teamId: string;
  teamName: string;
  updatedAt: Date;
  userId: string;
  versionName: string;
  bundle?: CartItem[];
  designState: {
    // TODO TO remove once the backend is give me creditsUsedInDesign in Cart.Pricing.creditsUsedInDesign
    customizationPricing: DesignPricing;
  };
}

export interface CartItemPricing {
  error: CartItemError;
  qty: number;
  minQty: number;
  minQtyPerSize: number;
  hasMinimum: boolean;
  currency: Currency;
  extraCredits: number;
  extraPricePerSize: ExtraPricePerSize[];
  freeCredits: number;
  hasMinimumPerSize: boolean;
  hasReachedMaximum: boolean;
  includedSizesPerRange: IncludedSizesPerRange[];
  price: number;
  priceOfExtraCreditsPerUnit: number;
  priceOfExtraSizes: number;
  priceOfOneExtraCredit: number;
  pricePerUnit: number;
  priceWithoutExtraSizesNorShipping: number;
  priceWithoutShipping: number;
}

export interface ExtraPricePerSize {
  price: number;
  priceOfOneExtraSize: number;
  qty: number;
  sizeName: string;
}

export interface IncludedSizesPerRange {
  extraSizePrice: number;
  fromSizeName: string;
  maxQty: number;
  minQty: number;
  sizesIncluded: { sizeName: string }[];
  toSizeName: string;
}

//match db

export interface Designs {
  collectable_since?: Date; // without TZ
  created_at: Date;
  deleted_at?: Date;
  description?: string;
  id: string;
  name?: string;
  project_id: string;
  updated_at: Date;
}

export interface OrderComment {
  comment: string;
  created_at: Date;
  deleted_at?: Date;
  id: string;
  order_id: string;
  updated_at: Date;
  user_id: string;
}

export interface CreateMockupForm {
  company: string;
  mockupId: string;
  sku: string;
  proposalNumber: string;
  graphicDesigner: string;
}

// export interface Order {
//   amount: number;
//   cartItemDetails: any;
//   checkoutSessionId?: string;
//   clientSecret?: string;
//   createdAt: Date;
//   currency: string;
//   deletedAt?: Date;
//   id: string;
//   invoiceNumber?: number;
//   paymentDetails?: any;
//   paymentIntentId?: string;
//   purchasedItems?: any; // TODO other object
//   status: string; // TODO might need to be a enum
//   updatedAt: Date;
//   userId: string;
// }

// export interface PreOrder {
//   amount: number;
//   cartItemDetails: any;
//   createdAt: Date;
//   currency: string;
//   deletedAt?: Date;
//   discounts: any;
//   externalInvoiceString?: string;
//   id: string;
//   paymentDetails?: any;
//   promoCode?: string;
//   purchasedItems?: any; // TODO other object
//   status: string; // TODO might need to be a enum
//   surcharges: any;
//   updatedAt: Date;
//   userId: string;
// }

import { Navigate, Routes as RRDRoutes, Route } from "react-router-dom";
import { UpdatePassword } from "../components/UpdatePassword";
import AdminOrders from "../containers/AdminOrdersContainer";
import AdminTechpackOrder from "../containers/AdminTechpackContainer";
import { Cart } from "../containers/Cart";
import CollectSizes from "../containers/CollectSizeContainer";
import Designs from "../containers/DesignsContainer/DesignsContainer";
import Home from "../containers/HomeContainer/Home";
import { OnboardingContainer } from "../containers/OnboardingContainer";
import Orders from "../containers/Orders/Orders";
import Team from "../containers/ProjectsContainer/Team/Team";
import Referral from "../containers/ReferralContainer";
import ReferralValidationContainer from "../containers/ReferralValidationContainer";
import { ShippingAddressContainer } from "../containers/ShippingAddressContainer";
import { Sizes } from "../containers/SizeContainer";
import PurchasedDesignStudioContainer from "../containers/StudioContainer/PurchasedDesignStudio";
import { StudioContainer } from "../containers/StudioContainer/StudioContainer";
import AuthService from "../service/authService";
import { ROUTES } from "../utils/Constant";
import LocalizedComponent from "./LocalizedComponent";

import { ErrorFallback } from "../common/components/ErrorFallback/ErrorFallback";
import { ResetPassword } from "../components/Onboarding/ResetPassword";
import AdminPreorderContainer from "../containers/AdminPreorderContainer";
import { CartOverview } from "../containers/Cart/CartOverview";
import { CollectSizeSuccess } from "../containers/CollectSizeContainer/CollectSizeSuccess";
import { JoinTeamWithTokenContainer } from "../containers/JoinTeam";
import { UserInfo } from "../types";
import { useThemeBreakpoints } from "../utils/themeUtils";
import { PrivateRoutes } from "./PrivateRoutes";

// handle route that ere admin only

const Routes = () => {
  const currentUser = AuthService.getUserInfo() as any as UserInfo;
  const { isMobile } = useThemeBreakpoints();

  return (
    <RRDRoutes>
      <Route path="/" element={<PrivateRoutes wrappedByAppContainer />}>
        <Route path={ROUTES.HOME} Component={Home} />

        {/** Only for admin designers that are editing an already purchased design */}

        <Route path={ROUTES.JOIN} Component={JoinTeamWithTokenContainer} />
        <Route
          path={ROUTES.DESIGNS_SHOW_NEW}
          Component={() => <Designs showNewDesignModal={true} />}
        />
        <Route path={ROUTES.REFER_A_FRIEND} Component={Referral} />

        <Route path={ROUTES.TEAM_PROJECTS} Component={Team} />
        <Route path={ROUTES.DESIGNS} Component={Designs}>
          <Route path={ROUTES.STUDIO} Component={StudioContainer} />
        </Route>

        <Route path={ROUTES.SIZES} Component={Sizes} />

        {/*ORDER_SESSION and ORDERS_PREORDERS need to be before ORDERS because it has a similar prefix path*/}
        <Route path={ROUTES.ORDERS_SESSION} Component={Orders} />
        <Route path={ROUTES.ORDERS_PREORDERS} Component={Orders} />
        <Route path={ROUTES.ORDERS} Component={Orders} />

        {currentUser?.isAdmin || currentUser?.isDesigner ? (
          <>
            <Route
              path={ROUTES.ADMIN_PURCHASED_ORDERS}
              Component={AdminOrders}
            />

            <Route
              path={ROUTES.ADMIN_VALIDATE_REFERRAL_CODE}
              Component={ReferralValidationContainer}
            />
          </>
        ) : null}
      </Route>

      {currentUser?.isAdmin || currentUser?.isDesigner ? (
        <Route path="/" element={<PrivateRoutes />}>
          <>
            <Route
              path={ROUTES.STUDIO_PURCHASED_DESIGN}
              Component={PurchasedDesignStudioContainer}
            />
            <Route
              path={ROUTES.ADMIN_TECHPACK_ORDER}
              Component={AdminTechpackOrder}
            />
            <Route
              path={ROUTES.ADMIN_TECHPACK_PREORDER}
              Component={AdminTechpackOrder}
            />
            <Route
              path={ROUTES.ADMIN_PREORDER}
              Component={AdminPreorderContainer}
            />
          </>
        </Route>
      ) : null}

      <Route
        path="/"
        element={<PrivateRoutes wrappedByAppContainer={isMobile} />}
      >
        <Route path={ROUTES.CART} Component={Cart}>
          <Route path={ROUTES.CART} Component={CartOverview} />
          <Route
            path={ROUTES.SHIPPING_ADDRESS}
            Component={ShippingAddressContainer}
          />
        </Route>
      </Route>

      {/**
       * It's very important that the routes with tokens are created BEFORE the routes with token, or else they won't be used
       * For example, here we created route LOGIN_WITH_TOKEN before the route for LOGIN. If we would have done reverse, the route
       * with token would not have been used and the token prop would always be set to undefined in the component.
       */}
      {/* Login */}
      <Route path={ROUTES.LOGIN_WITH_TOKEN} Component={OnboardingContainer} />
      <Route path={ROUTES.LOGIN} Component={OnboardingContainer} />

      <Route
        path={`/fr/${normalizePath(ROUTES.LOGIN_WITH_TOKEN)}`}
        Component={(props) => (
          <LocalizedComponent
            language="fr"
            component={OnboardingContainer}
            {...props}
          />
        )}
      />
      <Route
        path={`/en/${normalizePath(ROUTES.LOGIN_WITH_TOKEN)}`}
        Component={(props) => (
          <LocalizedComponent
            language="en"
            component={OnboardingContainer}
            {...props}
          />
        )}
      />

      <Route
        path={`/fr/${normalizePath(ROUTES.LOGIN)}`}
        Component={() => (
          <LocalizedComponent language="fr" component={OnboardingContainer} />
        )}
      />
      <Route
        path={`/en/${normalizePath(ROUTES.LOGIN)}`}
        Component={() => (
          <LocalizedComponent language="en" component={OnboardingContainer} />
        )}
      />

      {/* Sign up (see comments above about token routes) */}

      <Route path={ROUTES.SIGN_UP_WITH_TOKEN} Component={OnboardingContainer} />
      <Route
        path={`/fr/${normalizePath(ROUTES.SIGN_UP_WITH_TOKEN)}`}
        Component={(props) => (
          <LocalizedComponent
            language="fr"
            component={OnboardingContainer}
            {...props}
          />
        )}
      />
      <Route
        path={`/en/${normalizePath(ROUTES.SIGN_UP_WITH_TOKEN)}`}
        Component={(props) => (
          <LocalizedComponent
            language="en"
            component={OnboardingContainer}
            {...props}
          />
        )}
      />

      <Route path={ROUTES.SIGN_UP} Component={OnboardingContainer} />
      <Route
        path={`/fr/${normalizePath(ROUTES.SIGN_UP)}`}
        Component={(props) => (
          <LocalizedComponent
            language="fr"
            component={OnboardingContainer}
            {...props}
          />
        )}
      />
      <Route
        path={`/en/${normalizePath(ROUTES.SIGN_UP)}`}
        Component={(props) => (
          <LocalizedComponent
            language="en"
            component={OnboardingContainer}
            {...props}
          />
        )}
      />

      {/* Reset Password */}
      <Route path={ROUTES.RESET_PASSWORD} Component={ResetPassword} />
      <Route
        path={`/fr/${normalizePath(ROUTES.RESET_PASSWORD)}`}
        Component={(props) => (
          <LocalizedComponent language="fr" component={ResetPassword} />
        )}
      />
      <Route
        path={`/en/${normalizePath(ROUTES.RESET_PASSWORD)}`}
        Component={(props) => (
          <LocalizedComponent language="en" component={ResetPassword} />
        )}
      />

      <Route
        path={ROUTES.UPDATE_PASSWORD}
        Component={(props) => <UpdatePassword />}
      />

      {/* Collect sizes */}
      <Route
        path={ROUTES.COLLECT_SIZE_SUCCESS}
        Component={(props) => <CollectSizeSuccess />}
      />

      <Route
        path={ROUTES.COLLECT_SIZE}
        Component={(props) => <CollectSizes />}
      />

      <Route path={ROUTES.ERROR} element={<ErrorFallback />} />

      {/* Special hack here to forward the search query parameters (see https://github.com/remix-run/react-router/issues/5818) */}
      {/* // TODO vaid if its still working */}
      <Route
        path="/*"
        Component={(props) => (
          <Navigate
            to={{
              ...props,
              pathname: ROUTES.HOME,
            }}
          />
        )}
      />
    </RRDRoutes>
  );
};

/**
 * @param {string} path
 */
function normalizePath(path: string) {
  if (path.startsWith("/")) return path.substr(1, path.length);

  return path;
}

export default Routes;

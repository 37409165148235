import { Typography } from "@mui/material";
import { ChangeEvent, memo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Input } from "../../../common/components";
import { palette, spacing } from "../../../styles/theme";
import { Size } from "../../../types";

interface Props {
  itemSize: Size;
  disabled?: boolean;
  greyedOutInputColor?: boolean;
  onSizeChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    size: Size
  ) => void;
}

const CartItemSizeSimplified = ({
  itemSize,
  onSizeChange,
  disabled,
  greyedOutInputColor,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyItems={"center"}
      flexDirection={"column"}
      maxWidth={spacing.xlMobile}
    >
      <Typography variant="textRegularBold" whiteSpace={"nowrap"}>
        {t(`cart.sizesFromCartItem.${itemSize.name}`)}
      </Typography>
      <Input
        name={itemSize.name}
        inputProps={{
          sx: {
            textAlign: "center",
            color:
              itemSize.qty === 0 || greyedOutInputColor
                ? palette.grey.darkerGrey
                : undefined,
          },
        }}
        sx={{ background: "white !important" }}
        value={itemSize.qty}
        onChange={(e) => onSizeChange?.(e, itemSize)}
        disabled={disabled}
      />
    </Box>
  );
};

export default memo(CartItemSizeSimplified);

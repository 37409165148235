import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, FlexColBox, Icon } from "../../../common/components";
import config from "../../../config";
import { palette, spacing, Styles } from "../../../styles/theme";
import { CartItem } from "../../../types";
import { useCurrency } from "../../../utils/Currencies";
import CartItemPricingInfo from "../Components/CartItemPricingInfo";
import { ExtraSizeModal } from "../ExtraSizeModal";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: "12px",
  },
  description: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: { xs: spacing.xsMobile, lg: spacing.xsDesktop },
  },
  imgContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: spacing.xlMobile,
    maxWidth: spacing.xlMobile,
    py: "3px",
    "&>img": {
      maxHeight: "58px",
    },
  },
  bundleImgContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: spacing.largeDesktop,
    maxWidth: spacing.largeDesktop,
    py: "3px",
    "&>img": {
      maxHeight: "36px",
    },
  },
  bundleContainer: {
    display: "flex",
    flexDirection: "column",
    pl: "36px",
    gap: "8px",
  },
  bundle: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
};

const CartItemPreview = ({
  pricing,
  designName,
  designState,
  productImages,
  productName,
  designId,
  bundle,
  ...cartItem
}: CartItem) => {
  const [openSizeModal, setOpenSizeModal] = React.useState(false);
  const { t } = useTranslation();
  const { NarrowCurrencyFormatter } = useCurrency();
  const onOpenSizeModalClick = () => {
    setOpenSizeModal(true);
  };
  const closeOpenSizeModalClick = () => {
    setOpenSizeModal(false);
  };

  const deprecatedDesign = useMemo(() => {
    return (
      (cartItem?.deprecatedDesign &&
        config.blockPaymentWhenDesignIsDeprecated) ||
      false
    );
  }, [designState]);

  const hasExtraSizes = useMemo(
    () => pricing.priceOfExtraSizes > 0,
    [pricing.priceOfExtraSizes]
  );

  return (
    <FlexColBox gap={"8px"}>
      <Box sx={styles.container}>
        <Box sx={styles.imgContainer}>
          <img alt="Design" src={productImages[0].url} />
        </Box>
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={hasExtraSizes ? "flex-end" : "center"}
        >
          <Box sx={styles.description}>
            <Typography variant="textRegularBold">
              {designName || t("design.untitled_design")}
            </Typography>
            <Box>
              <CartItemPricingInfo
                deprecatedDesign={deprecatedDesign}
                pricing={pricing}
                productName={productName}
                designId={designId}
                designPricing={designState.customizationPricing}
              />
            </Box>
            {hasExtraSizes && (
              <Box display={"flex"} alignItems={"center"}>
                <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                  <Typography variant="textSm" color={palette.grey.primary}>
                    {`${pricing.extraPricePerSize.reduce(
                      (qtySum, extraPriceSizeObj) =>
                        qtySum + extraPriceSizeObj.qty,
                      0
                    )}
                 x `}
                    {t("checkout.extra_sizes")}
                  </Typography>
                  <Icon onClick={onOpenSizeModalClick} size={16} icon="info" />

                  <ExtraSizeModal
                    open={openSizeModal}
                    includedSizesPerRange={pricing.includedSizesPerRange}
                    onClose={closeOpenSizeModalClick}
                  />
                </Box>
              </Box>
            )}
          </Box>

          {!deprecatedDesign && pricing.hasReachedMaximum && (
            <Box display={"flex"} alignItems={"center"} minWidth="fit-content">
              <Typography variant="textRegular">
                {t("checkout.goingbig")}
              </Typography>
            </Box>
          )}
          {!deprecatedDesign &&
            pricing.hasMinimum &&
            pricing.hasMinimumPerSize &&
            !pricing.hasReachedMaximum && (
              <FlexColBox height={"100%"} alignItems={"flex-end"} gap={"4px"}>
                <Typography variant="textRegular">
                  {NarrowCurrencyFormatter?.format(
                    pricing.priceWithoutExtraSizesNorShipping
                  )}
                </Typography>
                {hasExtraSizes && (
                  <Typography variant="textRegular">
                    {NarrowCurrencyFormatter?.format(pricing.priceOfExtraSizes)}
                  </Typography>
                )}
              </FlexColBox>
            )}
        </Box>
      </Box>
      {!!bundle?.length && (
        <Box sx={styles.bundleContainer}>
          <Box sx={styles.bundle}>
            <Box sx={styles.bundleImgContainer}>
              <img alt="Design" src={productImages[0].url} />
            </Box>
            <Typography variant="textRegularBold">
              {designName || t("design.untitled_design")}
            </Typography>
          </Box>
          <Box sx={styles.bundle}>
            <Box sx={styles.bundleImgContainer}>
              <img alt="Design" src={productImages[0].url} />
            </Box>
            <Typography variant="textRegularBold">
              {designName || t("design.untitled_design")}
            </Typography>
          </Box>
        </Box>
      )}
    </FlexColBox>
  );
};

export default CartItemPreview;

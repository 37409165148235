import { Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Icon } from "../../../common/components";
import { Button } from "../../../common/components/Button";
import { usePageTitle } from "../../../common/hooks/usePageTitle";
import { useSelector } from "../../../common/hooks/useSelector";
import { CART_ACTION_ENUM } from "../../../common/reducers/cart";
import DeliveryInfoMessage from "../../../components/Message/DeliveryInfoMessage/DeliveryInfoMessage";
import { SpecsContext } from "../../../context/Specs/SpecsContext";
import { spacing, Styles } from "../../../styles/theme";
import { useCart } from "../../../utils/CartHooks";
import { canContinueToPayment } from "../../../utils/CartUtils";
import { ROUTES } from "../../../utils/Constant";
import { useCurrency } from "../../../utils/Currencies";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import { CartItemSizeSelector } from "../CartItemSizeSelector";

const styles: Styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    mb: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
  },
  emptyCart: {
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cartItems: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: spacing.largeMobile, lg: spacing.largeDesktop },
  },
};

export const CartOverview = () => {
  const userInfos = useSelector((state) => state.userInfo);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useThemeBreakpoints();
  usePageTitle(t("pages.cart.title"));
  const { dispatch } = useStore();
  const { isSaving } = useSelector((state) => state.cart);
  const { specs } = useContext(SpecsContext);

  const { loaded, pricingCartData } = useSelector((state) => state.cart);
  const items = useMemo(() => pricingCartData.items, [pricingCartData]);

  const { getCurrency } = useCurrency();

  const { removeFromCart, updateCartSizes, fetchCartItemsAndUpdateStore } =
    useCart();

  const saveItems = useCallback(
    _.debounce(
      async () => {
        await updateCartSizes(items, getCurrency());

        dispatch({
          type: CART_ACTION_ENUM.SET_SAVING,
          payload: { isSaving: false },
        });
      },
      1000,
      { leading: false }
    ),
    [items, dispatch, updateCartSizes]
  );

  /**
   * Remove from cart action
   */
  const onItemRemove = useCallback(
    (id: string, productName: string) => {
      removeFromCart(id, getCurrency(), productName);
    },
    [removeFromCart, getCurrency]
  );

  //Analytics
  useEffect(() => {
    if (loaded && pricingCartData && userInfos) {
      window.analytics.track("Cart Viewed", {
        cart_id: userInfos.id,
        products: pricingCartData?.items.map((cartItem) => {
          return {
            product_id: cartItem.productName,
            sku: cartItem.designId,
            name: cartItem.productName,
            quantity: cartItem.pricing.qty,
          };
        }),
      });
    }
  }, [loaded, pricingCartData, userInfos]);

  const itemsByDeliveryDate = useMemo(() => {
    const deliveryDates = items.map((item) => {
      const productDefinition = specs?.products.find(
        (product) => product.name === item.productName
      );
      const version = productDefinition?.versions.find(
        (v) => v.name === item.versionName
      );
      return version?.deliveryTime;
    });

    // Keep unique delivery dates.
    const uniqueDeliveryDates = new Map();
    for (const deliveryDate of deliveryDates) {
      const deliveryDateKey =
        deliveryDate?.minWeeks + "-" + deliveryDate?.maxWeeks;
      if (!uniqueDeliveryDates.has(deliveryDateKey)) {
        uniqueDeliveryDates.set(deliveryDateKey, deliveryDate);
      }
    }
    // Switch back to an array of obj
    const groupsOfDeliveryDates = Array.from(uniqueDeliveryDates.values());

    const groupedItems = [];
    for (const deliveryDate of groupsOfDeliveryDates) {
      const itemsWithSameDeliveryDate = items.filter((i) => {
        const productDefinition = specs?.products.find(
          (product) => product.name === i.productName
        );
        const version = productDefinition?.versions.find(
          (v) => v.name === i.versionName
        );

        return (
          version?.deliveryTime?.minWeeks === deliveryDate?.minWeeks &&
          version?.deliveryTime?.maxWeeks === deliveryDate?.maxWeeks
        );
      });
      groupedItems.push({
        items: itemsWithSameDeliveryDate,
        deliveryDate,
      });
    }
    return groupedItems;
  }, [items, specs?.products]);

  const onContinueToShippingClick = useCallback(async () => {
    navigate(ROUTES.SHIPPING_ADDRESS);
  }, []);

  return (
    <Box key="sizeCollection">
      <Box sx={styles.header}>
        <Typography variant="h1">{t("checkout.title")}</Typography>
        <a href={t("checkout.size_link")} target="_blank" rel="noreferrer">
          <Button
            color="primary"
            size="small"
            startIcon={<Icon icon="north_east" size={18} color="white" />}
            sx={{ borderRadius: { xs: "4px", lg: "8px" } }}
          >
            {t("checkout.view_sizes")}
          </Button>
        </a>
      </Box>
      <Box sx={styles.cartItems} key="cartItems">
        {itemsByDeliveryDate?.map((itemByDeliveryDate, index) => {
          return (
            <Box
              key={index}
              display={"flex"}
              flexDirection={"column"}
              gap={{ xs: spacing.mediumMobile, lg: spacing.mediumDesktop }}
            >
              <DeliveryInfoMessage
                minWeeks={itemByDeliveryDate.deliveryDate?.minWeeks}
                maxWeeks={itemByDeliveryDate.deliveryDate?.maxWeeks}
              />
              {itemByDeliveryDate.items.map((cartItem) => (
                <Box key={cartItem.id}>
                  <CartItemSizeSelector
                    key={cartItem.id}
                    item={cartItem}
                    onChange={saveItems}
                    onRemove={onItemRemove}
                  />
                </Box>
              ))}
            </Box>
          );
        })}
      </Box>
      {isMobile && (
        <Button
          sx={{ mt: spacing.largeMobile }}
          disabled={
            !canContinueToPayment(isSaving, items, pricingCartData, userInfos)
          }
          onClick={onContinueToShippingClick}
          fullWidth
        >
          {t("general.continue")}
        </Button>
      )}
    </Box>
  );
};

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Menu, { MenuOption } from "../../../common/components/Menu/Menu";

import { useTranslation } from "react-i18next";
import ProjectService from "../../../service/ProjectService";
import { useProjectFetcher } from "../../../utils/ProjectsFetcherHooks";

import { Skeleton, Typography } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { Box, Form, InlineInput } from "../../../common/components";
import { useForm } from "../../../common/hooks/useForm";
import { useSelector } from "../../../common/hooks/useSelector";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { AppLoadingContext } from "../../../context";
import { Styles, spacing } from "../../../styles/theme";
import { Project } from "../../../types";
import { useCart } from "../../../utils/CartHooks";
import { ROUTES } from "../../../utils/Constant";
import { useGlobalLoading } from "../../../utils/ContextHooks";
import { useCurrency } from "../../../utils/Currencies";
import { routeFormatter } from "../../../utils/Formatter";
import { useTeamFetcher } from "../../../utils/TeamsFetcherHooks";
import {
  getScreenWidthMinusSpacing,
  useThemeBreakpoints,
} from "../../../utils/themeUtils";

const styles: Styles = {
  container: {
    display: "flex",
    gap: { xs: spacing.xsMobile, lg: spacing.xsDesktop },
    alignItems: "center",
  },
  title: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginBottom: "1px",
  },
};

const validationSchema = object({
  name: string().required("general.errors.required.input"),
});

interface Props {
  canDeleteProject: boolean;
  canRenameProject: boolean;
  project?: Project;
}

const TopSection = ({ canDeleteProject, canRenameProject, project }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useThemeBreakpoints();
  const userInfo = useSelector((state) => state.userInfo);
  const { isGloballyLoading } = useGlobalLoading();
  const { setIsAppLoading } = useContext(AppLoadingContext);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { fetchCartItemsAndUpdateStore } = useCart();
  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();
  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();
  const { getCurrency } = useCurrency();

  const formik = useForm({
    initialValues: { name: project?.name },
    onSubmit: async (values) => {
      const trimmedValue = values.name.trim();
      const res = await ProjectService.updateProjectName({
        id: project?.id,
        name: trimmedValue,
      });

      if (res.status === 200) {
        await fetchMyProjectsAndUpdateStore();
        setEditMode(false);
        formik.setFieldValue("name", trimmedValue);
      } else {
        formik.setFieldError("name", t("project.error_occured"));
      }
    },
    validationSchema,
  });

  const handleSubmit = async () => {
    const isValid = !!formik.values.name;
    if (formik.dirty) {
      if (isValid) {
        await formik.submitForm();
      } else {
        await formik.resetForm();
      }
    }
    setEditMode(false);
  };

  const onDeleteClickClose = () => {
    setShowDeleteModal(false);
  };
  const onDelete = useCallback(async () => {
    try {
      await ProjectService.deleteProject({ id: project?.id });

      if (project?.team_id) {
        navigate(
          generatePath(ROUTES.TEAM_PROJECTS, {
            teamId: project?.team_id,
          })
        );
      } else {
        navigate(routeFormatter(ROUTES.HOME));
      }
      await fetchMyProjectsAndUpdateStore();
      await fetchCartItemsAndUpdateStore(getCurrency()); //Because deleting a Project and it's designs can impact the cart
    } catch (e) {
      console.error(e);
    } finally {
      setShowDeleteModal(false);
    }
  }, [
    project,
    fetchCartItemsAndUpdateStore,
    fetchMyProjectsAndUpdateStore,
    navigate,
    getCurrency,
  ]);

  useEffect(() => {
    formik.resetForm({ values: { name: project?.name } });
  }, [project?.name]);

  const onExport = useCallback(
    (language: "fr" | "en") => async () => {
      try {
        await ProjectService.downloadLeadTemplate(
          project?.id,
          project?.name,
          language
        );
      } catch (error: any) {
        toast.warn(
          error?.response?.data?.message ??
            "Something went wrong! Could not generate PDF"
        );
      }
    },
    [project?.id, project?.name]
  );

  const onTurnProjectIntoTeamClick = useCallback(async () => {
    try {
      const res = await ProjectService.turnIntoTeam(project?.id);
      await fetchMyTeamsAndUpdateStore();
      await fetchMyProjectsAndUpdateStore();
      navigate(
        generatePath(ROUTES.TEAM_PROJECTS, { teamId: res.data.data.id })
      );
    } catch (error: any) {
      toast.warn(
        error?.response?.data?.message ??
          "Something went wrong! Could not turn project into team"
      );
    }
  }, [project?.id, fetchMyTeamsAndUpdateStore, navigate]);

  const onDuplicateClick = useCallback(async () => {
    try {
      setIsAppLoading(true);
      const res = await ProjectService.duplicate(project?.id);
      await fetchMyProjectsAndUpdateStore();
      setIsAppLoading(false);
      navigate(generatePath(ROUTES.DESIGNS, { projectId: res.data?.data?.id }));
    } catch (error: any) {
      setIsAppLoading(false);
      toast.warn(
        error?.response?.data?.message ??
          "Something went wrong! Could not duplicate"
      );
    }
  }, [project?.id, fetchMyProjectsAndUpdateStore]);

  const options: MenuOption[] = useMemo(
    () => [
      {
        labelKey: "general.rename",
        disabled: !canRenameProject,
        onClick: () => setEditMode(true),
      },
      {
        labelKey: "project.delete_menu",
        disabled: !canDeleteProject,
        onClick: () => setShowDeleteModal(true),
      },
      {
        labelKey: "project.exportTemplateEn",
        onClick: onExport("en"),
        adminOnly: true,
      },
      {
        labelKey: "project.exportTemplateFr",
        onClick: onExport("fr"),
        adminOnly: true,
      },
      {
        labelKey: "project.projectIntoTeam",
        onClick: onTurnProjectIntoTeamClick,
        adminOnly: true,
      },
      {
        labelKey: "general.duplicate",
        onClick: onDuplicateClick,
      },
    ],
    [
      canRenameProject,
      canDeleteProject,
      canRenameProject,
      canDeleteProject,
      userInfo.isAdmin,
      userInfo.isDesigner,
      onExport,
      onTurnProjectIntoTeamClick,
      onDuplicateClick,
    ]
  );

  return (
    <Form formik={formik}>
      <Box sx={styles.container}>
        {isGloballyLoading || !project?.name ? (
          <Skeleton sx={{ minWidth: "300px" }} />
        ) : editMode ? (
          <InlineInput
            name="name"
            autoFocus
            onBlur={handleSubmit}
            maxWidth={
              isMobile
                ? getScreenWidthMinusSpacing("16px", "16px")
                : getScreenWidthMinusSpacing("260px", "96px")
            }
            defaultValue={project.name}
            onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
            inputStyle={{
              fontSize: isMobile ? 20 : 32,
              maxHeight: "unset",
              lineHeight: "normal",
            }}
            data-test="designContainer.topSection.projectNameInput"
          />
        ) : (
          <>
            <Typography
              variant="h1"
              sx={styles.title}
              onClick={() => setEditMode(true)}
              data-test="designContainer.topSection.projectName"
            >
              {project?.name}
            </Typography>
            <Menu
              options={options}
              iconSize={isMobile ? 22 : 32}
              disableArrowRotation
            />
          </>
        )}
        <DeleteModal
          open={showDeleteModal}
          onDelete={onDelete}
          title={t("project.delete_modal_title")}
          message={t("project.delete_modal_message")}
          onClose={onDeleteClickClose}
        />
      </Box>
    </Form>
  );
};

export default TopSection;

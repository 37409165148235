import { Typography, TypographyProps } from "@mui/material";
import { palette } from "../../../styles/theme";
type Props = TypographyProps;

export const ClickableText = (props: TypographyProps) => {
  return (
    <Typography
      sx={{
        cursor: "pointer",
        ":hover": {
          textDecoration: "underline",
        },
      }}
      {...props}
      color={palette.blue.secondary}
    />
  );
};

import { MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FlexColBox } from "../../../../../common/components";
import { Select } from "../../../../../common/components/Select/Select";
import { spacing } from "../../../../../styles/theme";

import styles from "../../StudioLeftSidebarMenu.module.scss";
import SubMenuOption from "../../SubMenuOption";

const SideMenuDecorations = (props) => {
  const { addOptions = [], decorations = [] } = props;
  const { t } = useTranslation();

  return (
    <li className={styles.sideNavItem} style={{ marginTop: "32px" }}>
      <div>
        <FlexColBox display={"flex"} width={"100%"} mb={spacing.mediumDesktop}>
          <Typography
            className={styles.specsLabel}
            mb={{ xs: spacing.mediumMobile, lg: spacing.mediumDesktop }}
            variant="textRegularBold"
          >
            {t(`specs.decorations`)}
          </Typography>
          <Select
            key="decoration"
            name="decoration"
            renderValue={() => {
              return t("specs.addDecorations");
            }}
            value={"1"}
            fullWidth
            controlled
          >
            <MenuItem disabled value="1" style={{ display: "none" }}>
              <em>{t("specs.addDecorations")}</em>
            </MenuItem>
            {addOptions.map((option) => (
              <MenuItem
                value={option}
                key={option.labelKey}
                onClick={option.onClick}
                disabled={option.disabled}
              >
                {t(option.labelKey)}
              </MenuItem>
            ))}
          </Select>
        </FlexColBox>
      </div>
      <ul className={styles.sideNavSecondLevel}>
        {decorations.map(
          (o) => o.display.displayable && <SubMenuOption {...o} key={o.id} />
        )}
      </ul>
    </li>
  );
};

export default SideMenuDecorations;

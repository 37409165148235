import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ProjectCard, {
  PROJECT_CARD_DESKTOP_MAX_WIDTH,
  PROJECT_CARD_DESKTOP_MIN_WIDTH,
} from "../../../components/ProjectCard/ProjectCard";

import { useTranslation } from "react-i18next";
import TeamTopSection from "./TeamTopSection";

import { Skeleton, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { Box, ContentRepeater } from "../../../common/components";
import GradientButton from "../../../common/components/Button/GradientButton";
import { usePageTitle } from "../../../common/hooks/usePageTitle";
import { useSelector } from "../../../common/hooks/useSelector";
import {
  DashboardToolbarDesktopHeight,
  DashboardToolbarMobileHeight,
} from "../../../components/AppToolbar/AppToolbar";
import InviteTeammateModal from "../../../components/InviteTeammateModal/InviteTeammateModal";
import ProjectCardSkeleton from "../../../components/ProjectCard/ProjectCardSkeleton";
import ProjectService from "../../../service/ProjectService";
import { Styles, spacing } from "../../../styles/theme";
import { ROUTES } from "../../../utils/Constant";
import { useGlobalLoading } from "../../../utils/ContextHooks";
import { useProjectFetcher } from "../../../utils/ProjectsFetcherHooks";
import { canDeleteUser, isCurrentUser } from "../../../utils/teamUtils";
import { getScreenHeightMinusSpacing } from "../../../utils/themeUtils";
import { Teammate, TeammateBlankState } from "./Teammate";
import TeammateSkeleton from "./Teammate/TeammateSkeleton";

const styles: Styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "100%",
    overflowY: "auto",
    maxHeight: {
      xs: getScreenHeightMinusSpacing(DashboardToolbarMobileHeight),
      lg: getScreenHeightMinusSpacing(DashboardToolbarDesktopHeight),
    },
  },
  leftContainer: {
    pt: { xs: spacing.wrapperMobile, lg: spacing.regularDesktop },
    pl: { xs: spacing.wrapperMobile, lg: spacing.largeDesktop },
    pr: { xs: spacing.wrapperMobile, lg: spacing.largeDesktop },
    width: "100%",
  },
  projectName: {
    mb: { xs: spacing.groupingMobile, lg: spacing.groupingDesktop },
  },
  buttonContainer: {
    mb: { xs: spacing.largeMobile, lg: spacing.largeDesktop },
    gap: { xs: spacing.ctaSpacingMobile, lg: spacing.ctaSpacingDesktop },
    display: "flex",
    alignItems: "center",
  },
  projectContainer: {
    pb: { xs: spacing.xlMobile, lg: spacing.xlDesktop },
    display: "grid",
    gridTemplateColumns: {
      xs: `1fr`,
      md: `minmax(${PROJECT_CARD_DESKTOP_MIN_WIDTH}, ${PROJECT_CARD_DESKTOP_MAX_WIDTH}) minmax(${PROJECT_CARD_DESKTOP_MIN_WIDTH}, ${PROJECT_CARD_DESKTOP_MAX_WIDTH})`,
    },
    gap: spacing.groupingDesktop,
  },
  projectsTitle: {
    mb: { xs: spacing.groupingMobile, lg: spacing.groupingDesktop },
  },
  rightContainer: {
    my: spacing.regularDesktop,
    mx: spacing.mediumDesktop,
    display: { xs: "none", lg: "block" },
    minWidth: "300px",
  },
};

const Team = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const {
    fetchMyProjectsAndUpdateStore,
    fetchTeamProjectsAndUpdateStore,
    resetTeamProjectsAndUpdateStore,
  } = useProjectFetcher();
  const [modalOpen, setModalOpen] = useState(false);
  const { isGloballyLoading } = useGlobalLoading();

  //At load, fetch all projects to get the latest information
  useEffect(() => {
    teamId && fetchTeamProjectsAndUpdateStore(teamId);
    return () => {
      resetTeamProjectsAndUpdateStore();
    };
  }, [fetchTeamProjectsAndUpdateStore, teamId]); //id is required to fetch each time we select a new team

  const userInfo = useSelector((state) => state.userInfo);

  const teamsState = useSelector((state) => state.myTeams);
  const teams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);
  const teamsLoaded = useMemo(() => {
    return teamsState?.loaded;
  }, [teamsState]);

  const team = useMemo(() => {
    return teams?.find((t) => t.id === teamId);
  }, [teams, teamId]);

  usePageTitle(`${t("pages.team.title", { id: team?.name })}`);

  const { teamProjects } = useSelector((state) => state.projects);

  const addProject = useCallback(async () => {
    const bodyData = {
      name: `${t("project.project")} ${teamProjects.length + 1}`,
      teamId: teamId,
    };

    const res = await ProjectService.createProject(bodyData);

    return res.data.data.id;
  }, [teamId, teamProjects.length, t]);

  const onAddProjectClick = useCallback(async () => {
    const projectId = await addProject();
    await fetchMyProjectsAndUpdateStore();
    teamId && (await fetchTeamProjectsAndUpdateStore(teamId));
    navigate(generatePath(ROUTES.DESIGNS, { projectId }));
  }, [addProject, fetchMyProjectsAndUpdateStore]);

  useEffect(() => {
    if (teamsLoaded && !team) {
      toast.warn(t("team.not_found"));
      navigate(ROUTES.HOME);
      return;
    }
  }, [navigate, teamsLoaded, team, t]);

  return (
    // <OverlayScrollbarWrapper>
    <Box sx={styles.container}>
      <Box sx={styles.leftContainer}>
        <Box sx={styles.projectName}>
          <TeamTopSection
            canDeleteTeam={userInfo.canDeleteTeam}
            canRenameTeam={userInfo.canRenameTeam}
            team={team}
          />
        </Box>
        <InviteTeammateModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          teamId={teamId}
        />
        {(userInfo.canAddProject || isGloballyLoading) && (
          <Box sx={styles.buttonContainer}>
            <GradientButton
              text={t("navigation.new_project")}
              data-test="team.newProject"
              icon={"add"}
              onClick={onAddProjectClick}
              isLoading={!team}
            />
            <GradientButton
              text={t("invite.invite_teammate")}
              data-test="team.inviteTeammate"
              icon={"person_add"}
              onClick={() => setModalOpen(true)}
              isLoading={!team}
            />
          </Box>
        )}
        <Box>
          <Typography sx={styles.projectsTitle} variant="h2">
            {isGloballyLoading || !team ? (
              <Skeleton variant="text" width={150} />
            ) : (
              t("project.projects")
            )}
          </Typography>

          <Box sx={styles.projectContainer}>
            {isGloballyLoading || !team ? (
              <ProjectCardSkeleton />
            ) : (
              teamProjects.map((project) => (
                <ProjectCard key={project.id} project={project} />
              ))
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.rightContainer}>
        <Box>
          <Typography variant="h2" mb={spacing.groupingDesktop}>
            {isGloballyLoading || !team ? (
              <Skeleton variant="text" width={150} />
            ) : (
              t("project.team")
            )}
          </Typography>
        </Box>
        <Box
          gap={"12px"}
          display="flex"
          flexDirection="column"
          maxWidth={"300px"}
        >
          {isGloballyLoading || !team ? (
            <ContentRepeater repeatCount={5}>
              <TeammateSkeleton />
            </ContentRepeater>
          ) : (
            <>
              {team?.teammates.map((teammate) => {
                return (
                  <Teammate
                    userId={teammate.id}
                    first_name={teammate.first_name}
                    last_name={teammate.last_name}
                    email={teammate.email}
                    imageUrl={teammate.imageUrl}
                    canDelete={canDeleteUser(team)}
                    key={teammate.id}
                    teamId={team.id}
                    teammateUserId={teammate.id}
                    isCurrentUser={isCurrentUser(userInfo, teammate.id)}
                    roles={teammate.roles}
                  />
                );
              })}
              <TeammateBlankState onClick={() => setModalOpen(true)} />
            </>
          )}
        </Box>
      </Box>
    </Box>
    // </OverlayScrollbarWrapper>
  );
};

export default Team;
